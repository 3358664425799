import { createSlice } from "@reduxjs/toolkit";
import { getProducts, getProduct, getQuery } from "./ProductAction";

const ProductsReducer = createSlice({
    name: 'ProductsReducer',
    initialState: {
        products: [],
        productsContainer: [],
        loader: false,
        errors: {},
        product: {}
    },
    reducers: {
        filteredProducts: (state, action) => {
            state.productsContainer = state.products.filter((product) => 
                product.title.toLowerCase().includes(action.payload)
            )
        }
    },
    extraReducers: {
        // All Products
        [getProducts.pending]: (state) => {
            state.loader = true;
        },
        [getProducts.fulfilled]: (state, action) => {
            state.loader = false;
            state.products = action.payload;
            state.productsContainer = action.payload;
        },
        [getProducts.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
        // Single Product
        [getProduct.pending]: (state) => {
            state.loader = true;
        },
        [getProduct.fulfilled]: (state, action) => {
            state.loader = false;
            state.product = action.payload;
        },
        [getProduct.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
        // Search Products
        [getQuery.pending]: (state) => {
            state.loader = true;
        },
        [getQuery.fulfilled]: (state, action) => {
            state.loader = false;
            state.products = action.payload;
            state.productsContainer = action.payload;
        },
        [getQuery.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
    }
})

export const { filteredProducts } = ProductsReducer.actions;
export default ProductsReducer.reducer;