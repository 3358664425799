import { createSlice, current } from "@reduxjs/toolkit";
import { AddToCart, IncQty, DecQty, RemovePro, Delivery, GrandTotal, CODCHK, CouponAc, SAddress } from "./CartActions";
import { notification } from 'antd';

/*const Notify = (msg) => {
    Modal.success({
        title: msg,
        afterClose: () => {
            window.location.reload(true);
        }
    });
}*/

const args = ({ title, msg }) => {
    const args = {
      message: title,
      description: msg,
    };

    return args;
};

const CartReducers = createSlice({
    name: 'CartReducers',
    initialState: {
        products: [],
        totalQtys: 0,
        totalPrice: 0,
        overWeight: 0,
        delivery: 0,
        grandTotal: 0,
        errors: {},
        isCOD: true,
        couponData: {},
        shippingAdrs: {}
    },
    reducers: {},
    extraReducers: {
        // Add_To_Cart
        [AddToCart]: (state, action) => {
            const { product, qty } = JSON.parse(JSON.stringify(action.payload));
            const { products } = current(state);
            const findPro = state.products.find(pro => pro.id === product.id);
            const index = products.findIndex(pro => pro.id === product.id);
            if(Number(qty) > 0) {
            if (findPro) 
            {
                findPro.ow = Number(findPro.ow + (findPro.dfee * qty));
                findPro.quantity = findPro.quantity + Number(qty);
                state.products[index] = findPro;

                if(findPro.sale_price != null) {
                    const Tprice = state.totalPrice + Number(findPro.sale_price) * Number(qty);
                    state.totalPrice = Tprice;
                } else {
                    const Tprice = state.totalPrice + Number(findPro.regular_price) * Number(qty);
                    state.totalPrice = Tprice;
                }
                const Tqtys = state.totalQtys + Number(qty);
                state.totalQtys = Tqtys;
                
                const Toverweight = state.overWeight + Number((findPro.dfee * qty));
                state.overWeight = Toverweight;
                state.totalPrice = state.totalPrice + Number((findPro.dfee * qty));
            } else {
                product.ow = Number(product.dfee * qty);
                if(product.dfee === null) {
                    product.dfee = 0;
                }
                product.quantity = Number(qty);
                state.products.push(product);

                if(product.sale_price != null) {
                    const Tprice = state.totalPrice + Number(product.sale_price) * Number(qty);
                    state.totalPrice = Tprice;
                } else {
                    const Tprice = state.totalPrice + Number(product.regular_price) * Number(qty);
                    state.totalPrice = Tprice;
                }
                const Tqtys = state.totalQtys + Number(qty);
                state.totalQtys = Tqtys;

                const Toverweight = state.overWeight + Number((product.dfee * qty));
                state.overWeight = Toverweight;
                state.totalPrice = state.totalPrice + Number((product.dfee * qty));
            }
            let proTitleChck = `${product.title !== "variable" ? product.title : product.variation_desc}`;
            notification.success(args({title: "Added to cart!",  msg: proTitleChck}));
            } else {
                notification.error(args({title: "Please add a quantity"}));

            }
        },
        // Increment Quantity
        [IncQty]: (state, action) => {
            const findPro = state.products.find(product => product.id === action.payload);
            const index = state.products.findIndex(product => product.id === action.payload);
            findPro.ow += Number(findPro.dfee);
            findPro.quantity += 1;
            state.products[index] = findPro;

            if(findPro.sale_price != null) {
                const Tprice = state.totalPrice + Number(findPro.sale_price);
                state.totalPrice = Tprice;
            } else {
                const Tprice = state.totalPrice + Number(findPro.regular_price);
                state.totalPrice = Tprice;
            }
            const Tqtys = state.totalQtys + 1;
            state.totalQtys = Tqtys;

            const Toverweight = state.overWeight + Number(findPro.dfee);
            state.overWeight = Toverweight;
            state.totalPrice = state.totalPrice + Number(findPro.dfee);

            let proTitleChck = `${findPro.title !== "variable" ? findPro.title : findPro.variation_desc}`;
            notification.success(args({title: "Updated successfully!",  msg: proTitleChck}));

        }, 
        // Decrement Quantity
        [DecQty]: (state, action) => {
            const findPro = state.products.find(product => product.id === action.payload);
            const index = state.products.findIndex(product => product.id === action.payload);
 
            if(findPro.quantity > 1){
                findPro.ow -= Number(findPro.dfee);
                findPro.quantity -= 1;
                state.products[index] = findPro;
    
                if(findPro.sale_price != null) {
                    const Tprice = state.totalPrice - Number(findPro.sale_price);
                    state.totalPrice = Tprice;
                } else {
                    const Tprice = state.totalPrice - Number(findPro.regular_price);
                    state.totalPrice = Tprice;
                }
                const Tqtys = state.totalQtys - 1;
                state.totalQtys = Tqtys;

                const Toverweight = state.overWeight - Number(findPro.dfee);
                state.overWeight = Toverweight;
                state.totalPrice = state.totalPrice - Number(findPro.dfee);
            } else {
                return state;
            }

            let proTitleChck = `${findPro.title !== "variable" ? findPro.title : findPro.variation_desc}`;
            notification.success(args({title: "Updated successfully!",  msg: proTitleChck}));
        },
        // Remove Product
        [RemovePro]: (state, action) => {
            const findPro = state.products.find(product => product.id === action.payload);
            const filtered = state.products.filter(product => product.id !== action.payload);

            notification.error(args({msg: "Item removed from cart successfully."}));

            state.products = filtered;
            if(findPro.sale_price != null) {
                const Tprice = state.totalPrice - findPro.sale_price * findPro.quantity;
                state.totalPrice = Tprice;
            } else {
                const Tprice = state.totalPrice - findPro.regular_price * findPro.quantity;
                state.totalPrice = Tprice;
            }
            const Tqtys = state.totalQtys - findPro.quantity;
            state.totalQtys = Tqtys;

            const Toverweight = state.overWeight - findPro.ow;
            state.overWeight = Toverweight;
            state.totalPrice = state.totalPrice - findPro.ow;
        },
        // Delivery Charges
        [Delivery]: (state, action) => {
            state.delivery = Number(action.payload);
        },

        // Coupon
        [CouponAc]: (state, action) => {
            state.couponData = action.payload;
        },
       
        // COD Check
        [CODCHK]: (state, action) => {
            state.isCOD = action.payload;
        },

        // Grand Total
        [GrandTotal]: (state, action) => {
            state.grandTotal = Number(action.payload);
        },

        // Shipping address
        [SAddress]: (state, action) => {
            state.shippingAdrs = action.payload;
        },
    }
})

export default CartReducers.reducer;