import { createSlice } from "@reduxjs/toolkit";
import { getBrands, getBrand } from "./BrandActions";

const BrandReducers = createSlice({
    name: "BrandReducers",
    initialState: {
        brands: [],
        brandsContainer: [],
        loader: false,
        error: {},
        data: []
    },
    reducers: {},
    extraReducers: {
        // All Brands
        [getBrands.pending]: (state) => {
            state.loader = true;
        },
        [getBrands.fulfilled]: (state, action) => {
            state.loader = false;
            state.brands = action.payload;
            state.brandsContainer = action.payload;
        },
        [getBrands.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
        // Single Brand
        [getBrand.pending]: (state) => {
            state.loader = true;
        },
        [getBrand.fulfilled]: (state, action) => {
            state.loader = false;
            state.data = action.payload;
        },
        [getBrand.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        }
    }
});

export default BrandReducers.reducer;