import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { root, dsinPath } from '../../Constants';
import { Autoplay, Navigation } from "swiper";
import axios from 'axios';
import './style.css';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import FetchBC from './FetchBC';
import { Link } from "react-router-dom";
import Coming from "./coming.jpg";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';


const Home = () => {
  const [Hero, setHero] = useState([]);
  const [CB, setCB] = useState([]);
  const [SB, setSB] = useState([]);
  const [FBrands, setFBrands] = useState([]);
  // Top Categories
  const [Arrivals, setArrivals] = useState([]);
  const [HotSelling, setHotSelling] = useState([]);
  const [TopOffers, setTopOffers] = useState([]);
  const [ivoclar, setIvoclar] = useState([]);
  const [denpro, setDenpro] = useState([]);
  const [mani, setMani] = useState([]);

  // Navigation Prev/Next Ref
  const fb_NPR = useRef(null);
  const fb_NNR = useRef(null);
  const fc_NPR = useRef(null);
  const fc_NNR = useRef(null);

  const fetchingData = async () => {
    try {
      await axios.get(`${root}/api/banner/hero-slider`).then((response) => { setHero(response.data) });
      await axios.get(`${root}/api/banner/category`).then((response) => { setCB(response.data) });
      await axios.get(`${root}/api/banner/sidebar`).then((response) => { setSB(response.data) });
      await axios.get(`${root}/api/featured-brands`).then((response) => { setFBrands(response.data) });
      // Top Categories
      await axios.get(`${root}/api/allproducts`).then((response) => { setArrivals(response.data) });
      await axios.get(`${root}/api/category/hot-selling.html`).then((response) => { setHotSelling(response.data) });
      await axios.get(`${root}/api/category/top-offers.html`).then((response) => { setTopOffers(response.data) });
      await axios.get(`${root}/api/brand/ivoclar.html`).then((response) => { setIvoclar(response.data) });
      await axios.get(`${root}/api/brand/prevest-denpro.html`).then((response) => { setDenpro(response.data) });
      await axios.get(`${root}/api/brand/mani.html`).then((response) => { setMani(response.data) });
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchingData();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${dsinPath}`} />
        <meta name="keywords" content="Dental Products, Dental Products online, Buy Dental Products, Dental Products Price,Dental Products Online India, Dental Products Price In India" />
        {generateHeadTags({
          title: 'DentalStores.in: Buy Dental Products Online at Best Price',
          description: 'Every dentist\'s Favorite place to buy all their needed Products. Get Genuine Products from Top Brands. Choose from 20000+ Products at Lowest Prices. Fast Delivery Service. Pan India Delivery & COD available.',
          openGraph: {
            title: 'DentalStores.in: Buy Dental Products Online at Best Price',
            url: `${dsinPath}`,
            'article:author': 'DentalStores.in',
            'article:tag': ['dental', 'online store', 'dentalstores.in', 'dental products online'],
          },
        })}
      </Helmet>
      {/* Hero banner section */}
      <section className='hero__section'>
        {!!Hero ?
          <Swiper
            grabCursor={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={false}
            effect="fade"
            modules={[Autoplay]}
            className="mySwiper"
          >
            {!!Hero.banner ? Hero.banner.map((poster, i) =>
              <SwiperSlide key={i}>
                <a href={poster.link}>
                  <img src={root + poster.banner} alt={poster.alt} className="hero__img" />
                </a>
              </SwiperSlide>
            ) : null}
          </Swiper>
          : <img src='./hero.jpg' alt="hero-img" className="hero__img" />}
      </section>
      {/* FEATURED BRANDS */}
      <section className="section">
        <div className="sideNavPrev">
          <span className="section__small-title">Most Popular | Our Favorites | Top Rated</span>
          <h2 className="section__title">Featured Brands<span>.</span></h2>
          {Object.keys(FBrands).length !== 0 ?
          <Swiper
            grabCursor={true}
            spaceBetween={10}
            slidesPerView="auto"
            autoplay={{
              delay: 1000,
              disableOnInteraction: true,
            }}
            navigation={{
              prevEl: fb_NPR.current,
              nextEl: fb_NNR.current,
            }}
            modules={[Navigation, Autoplay]}
            className="fbrands__container"
          >
            {!!FBrands.brands ? FBrands.brands.map((brand, i) =>
              <SwiperSlide className="fb__card" key={i}>
                <Link to={"/brand/" + brand.slug + ".html"}>
                  <img src={root + brand.thumbnail} alt={brand.slug} />
                </Link>
              </SwiperSlide>
            ) : null}
            <SwiperSlide className="fb__card">
              <Link to="/brands">
                <h3>View all</h3>
              </Link>
            </SwiperSlide>

            <div className="swiper-button-prev" ref={fb_NPR}>
              <BiChevronLeft />
            </div>
            <div className="swiper-button-next" ref={fb_NNR}>
              <BiChevronRight />
            </div>
          </Swiper>
          : <p>No Featured Brands</p>}
        </div>
      </section>
      {/* FEATURED CATEGORIES */}
      <section className="section">
        <div className="fcategories__container">
          <div className="sideNavPrev">
            <span className="section__small-title">Most Popular | Our Favorites | Top Rated</span>
            <h2 className="section__title">Featured Categories<span>.</span></h2>
            {Object.keys(CB).length !== 0 ?
            <Swiper
              grabCursor={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
              }}
              loop={false}
              effect="fade"
              spaceBetween={4}
              slidesPerView="auto"
              navigation={{
                prevEl: fc_NPR.current,
                nextEl: fc_NNR.current,
              }}
              modules={[Navigation, Autoplay]}
              className="fcSwiper"
            >
              {!!CB.banner ? CB.banner.map((poster, i) =>
              <SwiperSlide className="fc__card" key={i}>
                <a href={poster.link}>
                  <img src={root + poster.banner} alt={poster.alt} />
                </a>
              </SwiperSlide>
              ) : null}
              <div className="swiper-button-prev" ref={fc_NPR}>
                <BiChevronLeft />
              </div>
              <div className="swiper-button-next" ref={fc_NNR}>
                <BiChevronRight />
              </div>
            </Swiper>
            : <img src='./hero.jpg' alt="hero-img" className="hero__img" />}
          </div>
          <div className="app__download">
          {!!SB.banner ? SB.banner.map((poster, i) =>
            <a href={poster.link}>
              {/* <img src={Coming} alt="app" /> */}
              <img src={root + poster.banner} alt={poster.alt} />
            </a>
          ) : null}
          </div>
        </div>
      </section>
      {/* Categories & Brands */}
      <FetchBC type={Arrivals} title="New Arrivals" link="/new-arrivals.html" />
      {/* <FetchBC type={HotSelling} title="Hot Selling" link="/hot-selling.html" /> */}
      <section className="section">
        <div className="free__shipping">
          <img src="https://images.dentalstores.in/images/ffshipping.jpg" alt="free-shipping" />
        </div>
      </section>
      {/* <FetchBC type={TopOffers} title="Top Offers" link="/top-offers.html" /> */}
      <section className="section d-none">
        <div className="super__sale">
          <img src="https://images.dentalstores.in/banners/supersale.jpg" alt="super-sale" />
        </div>
      </section>
      <FetchBC type={ivoclar} title="Ivoclar" link="/ivoclar.html" />
      <FetchBC type={denpro} title="Prevest Denpro" link="/prevest.html" />
      <FetchBC type={mani} title="Mani" link="/mani.html" />
    </>
  )
}

export default Home;