function isValidEmail(value) {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/;
    return re.test(String(value)?.toLowerCase());
}

function validEmail(value, setEmailError) {
    if(value === "") {
        setEmailError("")
    } 
    else if(isValidEmail(value)) {
        setEmailError("")
    }
    else {
        setEmailError("Invalid Email")
    }
}

function validPassword(value, setPasswordError) {
    if(value === "") {
        setPasswordError("")
    } 
    else if(value.length < 8) {
        setPasswordError("Password must be 8 characters")
    }
    else {
        setPasswordError("")
    }
}

function validUsername(value, setUsernameError) {
    if(value === "") {
        setUsernameError("")
    } 
    else if(value.length < 5) {
        setUsernameError("Please enter at least 5 characters.")
    }
    else {
        setUsernameError("")
    }
}

function isValidNumber(value) {
    const re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    return re.test(String(value)?.toLowerCase());
}

function validNumber(value, setNumberError) {
    if(value === "") {
        setNumberError("")
    } 
    else if(isValidNumber(value)) {
        setNumberError("")
    }
    else {
        setNumberError("Please put 10 digit mobile number.")
    }
}

const utils = {
    isValidEmail,
    validEmail,
    validPassword,
    validUsername,
    isValidNumber,
    validNumber
}

export default utils;