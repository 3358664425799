import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { DiDropbox } from "react-icons/di";
import currencyFormatter from "currency-formatter";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../../Redux/User/UserActions";
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { dsinPath } from "../../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Orders = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data, loader } = useSelector((state) => state.user);

    useEffect(() => {
        if (!localStorage.getItem('auth_token')) navigate('/login');
        dispatch(getOrders());
    }, [dispatch, navigate]);

    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }

    const formatDate = (date) => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        return new Date(date).toLocaleDateString(undefined, options)
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'All Orders | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'All Orders | DentalStores.in',
                    url: `${dsinPath}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <div className="myaccount">
                <Breadcrumb className="myaccount__breadcrumb-container">
                        <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/account">My Account</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Orders</Breadcrumb.Item>
                </Breadcrumb>
                <div className="dashboard__section">
                    <p className="ac__section-title"><DiDropbox size={25} /> Orders</p>
                    {(data.orders || []).length > 0 ?
                    <>
                        {!loader ? data.orders.map((order, index) => (
                            <div className="order-item-group" key={index}>
                                <div className="heading">
                                    <div className="left-content">
                                        <div className="id">Order ID: {order.track_no}</div>
                                        <div className="time">Placed on {formatDate(order.created_at)}</div>
                                    </div>
                                    <div className="right-content">
                                        {/* <div className="invoice"><Link to="#">Get Invoice</Link></div> */}
                                        <Link to={`/account/order/${order.track_no}`}><button className="view-btn">View <BiChevronRight /></button></Link>
                                    </div>
                                </div>
                                <div className="body">
                                    <div className="title">
                                        { 
                                            order.order_status === "Completed" ? 
                                            "Delivered Successfully!" :
                                            (order.order_status === "Processing") && (order.provider_track_link !== null) ?
                                            "Dispatched Successfully!" :
                                            order.order_status === "On hold" ? 
                                            <span className="text-warning">Order is on hold</span> : 
                                            order.order_status === "Cancelled" ? 
                                            <span className="text-danger">Cancelled</span> : 
                                            order.order_status === "RTO" ? 
                                            <span className="text-danger">RTO</span> :
                                            order.order_status === "Processing" ? 
                                            <><span>Placed Successfully!</span>{order.edd !== null ? <span style={{display: "block", fontSize: "0.7rem", color: "#475569", textTransform: "none"}}>(Estimated Dispatch on {formatDate(order.edd)})</span> : null }</> : "Placed Successfully!"
                                        }
                                    </div>
                                    <div className="paid">
                                        <span>Payment</span>
                                        <span>{order.payment_type}</span>
                                    </div>
                                    <div className="saved">
                                        <span>Order Total</span>
                                        <span>{formatAmount(order.total_amount)}</span>
                                    </div>
                                </div>
                            </div>
                        )) : 'loading...'}
                    </> : "You have not ordered any item yet."
                    }
                </div>
            </div>
        </>
    )
}

export default Orders;