import React from 'react';
import ReactDOM from 'react-dom/client';
import 'swiper/css';
import "swiper/css/navigation";
import "antd/dist/antd.min.css";
import App from './App';
import './style.css';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
)