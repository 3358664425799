import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import axios from "axios";
import { root, dsinPath } from "../../Constants";
import { TextButton, Variation } from "../../Components";
import { HiShoppingCart, HiShoppingBag, HiOutlineHeart, HiInformationCircle, HiBan } from "react-icons/hi";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Popover, Collapse, Alert } from 'antd';
import { Link } from "react-router-dom";
import currencyFormatter from "currency-formatter";

import { getProduct } from "../../Redux/Products/ProductAction";
import { AddToCart } from "../../Redux/ShoppingCart/CartActions";

// Import Swiper React components & required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

// Import ReactIamgeMagnify
// import ReactImageMagnify from "react-image-magnify";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsWhatsapp, BsFacebook, BsTwitter } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./style.css";

import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


const Gallery = ({ images, ptitle }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="product__details-gallery">
            <div className="product__details__gallery-content">
                
                {/* Large photo */}
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#000"
                    }}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="myGallery2"
                >
                    
                        {images.map((url, index) =>
                            <SwiperSlide key={index}>
                                <img src={url} alt={`${ptitle}`} />
                            </SwiperSlide>    
                        )}
                </Swiper>
                {/* Thumbnails */}
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView='auto'
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="myGallery d-none"
                >
                    {images.map((url, index) =>
                        <SwiperSlide key={index}>
                            <img src={url} alt={`${ptitle}`} />
                        </SwiperSlide>
                    )}
                </Swiper>
                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames="custom-class-name"

                >
                    {images.map((url, index) =>
                        <div key={index} data-src={url}>
                            <img src={url} alt={`${ptitle}`} />
                        </div>
                    )}
                </LightGallery>

                <div className="product__details-functional__buttons" style={{ display: "none" }}>
                    <TextButton
                        label="Add to cart"
                        iconLeft={<HiShoppingCart color="white" size={20} style={{ marginRight: 5 }} />}
                        buttonContainerStyle={{
                            height: 50,
                            flex: 1,
                            backgroundColor: "hsl(232, 44%, 17%)",
                        }}
                        labelStyle={{
                            // color: "hsl(232, 44%, 17%)",
                            textTransform: "uppercase"
                        }}
                        onClick={() => { }}
                    />
                    <TextButton
                        label="Buy now"
                        iconLeft={<HiShoppingBag color="white" size={20} style={{ marginRight: 5 }} />}
                        buttonContainerStyle={{
                            height: 50,
                            flex: 1,
                            backgroundColor: "hsl(352, 96%, 65%)",
                        }}
                        labelStyle={{
                            // color: "hsl(352, 96%, 65%)",
                            textTransform: "uppercase"
                        }}
                        onClick={() => { }}
                    />
                </div>
            </div>
        </div >
    )
}

// Price Section
const formatAmount = (price) => {
    return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
}

const Details = ({
    product,
    brand,
    regularPrice,
    salePrice,
    tab,
    varLength,
    proVariations,
    categories
}) => {

    const { Panel } = Collapse;
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1);
    const inputRef = useRef();
    const AddQty = () => {
        setQty(parseInt(parseInt(inputRef.current.value)))
    }

    // Dispatch Time Content
    const dtContent = (
        <div style={{ maxWidth: 300, height: 200, overflowY: "scroll", fontSize: "0.8rem" }}>
            <p>Dispatch days mentioned here is valid for this product only, subject to availability at the time of order.</p>
            <p>Days required to actually dispatch an order from our warehouse may depend on various factors like</p>
            <ol>
                <li>Order received after cut-off delivery pick-up time of 5.00pm.</li>
                <li>Holidays, Festivals, Logistic partners schedule.</li>
                <li>Multiple products in an order. (In case if an order has multiple items together, the actual dispatch date from the warehouse may depend on the product with the longest dispatch days.)</li>
                <li>Natural Calamities (floods, earthquakes, landslides, pandemics etc) or embargo situations.</li>
                <li>Curfews, Elections, Bandhs etc.</li>
                <li>Dental Exhibitions - Product supply from manufacturers / suppliers is affected during or after exhibitions.</li>
                <li>Customs / CHA Strikes etc. - Product supply will be hamper if there is a custom / CHA strike as import clearance from customs will be affected.</li>
                <li>Audit Checks – During Audit inventory checks, there may be some delay as it requires holding all orders till the process is completed.</li>
            </ol>
        </div>
    );

    return (
        <>
            <Helmet>
                <meta name="robots" content={`${product.meta_robots}`} />
                <link rel="canonical" href={`${dsinPath}/product/${product.slug + ".html"}`} />
                <meta name="keywords" content={`${product.meta_keywords}`} />
                {generateHeadTags({
                    title: `${product.title}`,
                    description: `${product.meta_desc}`,
                    openGraph: {
                        title: `${product.title}`,
                        url: `${dsinPath}/product/${product.slug + ".html"}`,
                        image: `${root + product.thumbnail}`,
                        'article:author': 'DentalStores.in',
                        'article:tag': [`${product.meta_keywords}`],
                        'article:published_time': `${product.created_at}`,
                        'article:modified_time': `${product.updated_at}`,
                    },
                })}
            </Helmet>
            <div className="product__details-content">
                {/* Breadcrumb */}
                <Breadcrumb className="product__breadcrumb-container">
                    <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
                    {
                        Object.keys(product).length !== 0 ? (
                            categories.map((category, i) =>
                                <Breadcrumb.Item key={i}>
                                    <Link to={"/" + category.slug + ".html"}>{category.name}</Link>
                                </Breadcrumb.Item>
                            )
                        ) : null

                    }
                    <Breadcrumb.Item>{product.title}</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="product__details-title">{product.title}</h1>
                {<div className="product__details-shortDesc" dangerouslySetInnerHTML={{ __html: product.short_desc }} />}
                {product.codchk === "0" ?
                    <Alert message="Cash on Delivery is not available." type="error" showIcon style={{ maxWidth: 500 }} />
                    : null}
                <div className="product__details-manufacturer">
                    <span>Manufacturer: </span>
                    {brand.thumbnail !== undefined ?
                        <figure className="mfr">
                            <Link to={"/brand/" + brand.slug + ".html"}><img src={root + brand.thumbnail} alt={brand.slug} width={85} /></Link>
                        </figure> : "Undefined"}
                </div>
                {/* Dispatch Time */}
                <div className="product__dispatch">
                    <span>Dispatch time: |&nbsp; </span>
                    <Popover content={dtContent} title="Dispatch time">
                        <span className="content">{product.dispatch !== null ? product.dispatch : "1-2"} working days &nbsp;<HiInformationCircle size={20} /></span></Popover>
                </div>
                <div className="product__details-price__section">
                    {varLength > 0 ?
                        <>
                            {product.stock_status === "In stock" ?
                                <div style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}><span>Availability:&nbsp;</span> <span style={{ color: '#62c971', fontWeight: 500, }}>In stock</span></div>
                                : <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}><span>Availability:&nbsp;</span><span style={{ justifyContent: "center", color: 'red', fontWeight: 500, cursor: 'not-allowed', backgroundColor: 'rgba(352, 96, 65, 0.1)', padding: '.5rem 1rem', userSelect: 'none', borderRadius: 5, display: 'inline-flex', alignItems: 'center' }}><HiBan size={18} /> &nbsp;Out of stock</span></div>
                            }
                            <span className="starting">Starting: </span>
                            <span className="sale__price">{regularPrice && salePrice ? formatAmount(Math.min.apply(Math, salePrice)) : formatAmount(Math.min.apply(Math, regularPrice))}</span>
                        </>
                        :
                        <>
                            {product.regular_price && product.sale_price ?
                                <>
                                    <span className="sale__price">{formatAmount(product.sale_price)}</span>
                                    <span className="mrp__price">{formatAmount(product.regular_price)}</span>
                                    <span className="off">({parseInt((product.regular_price - product.sale_price) / (product.regular_price) * 100)}% Off)</span>
                                    &nbsp;{product.dfee !== null ? <span className="sale__price" style={{ color: "#0d6efd", fontSize: "0.8rem" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null}
                                    <div className="you__save">You save: {formatAmount(product.regular_price - product.sale_price)}/- (incl. of all taxes)</div>
                                </>
                                : <><span className="sale__price">{formatAmount(product.regular_price)}</span>{product.dfee !== null ? <span className="sale__price" style={{ color: "#0d6efd", fontSize: "0.8rem" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null}</>
                            }
                            {/* Quantity */}
                            {product.stock_status === "In stock" ?
                                <div className="" style={{ margin: "1.5rem 0" }}>
                                    <div style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}><span>Availability:&nbsp;</span> <span style={{ color: '#62c971', fontWeight: 500, }}>In stock</span></div>
                                    <div className="quantity__container">
                                        <span>Quantity: </span>
                                        <input type="number" min={1} onChange={AddQty} ref={inputRef} value={qty} />
                                        <div className="qty__action-btns">
                                            <TextButton
                                                label="Add to cart"
                                                iconLeft={<HiShoppingCart color="white" size={20} style={{ marginRight: 5 }} />}
                                                buttonContainerStyle={{
                                                    height: 40,
                                                    padding: ".75rem",
                                                    borderRadius: 3
                                                }}
                                                onClick={() => dispatch(AddToCart(product, qty))}
                                            />
                                            <TextButton
                                                label="Wishlist"
                                                iconLeft={<HiOutlineHeart size={20} style={{ marginRight: 5 }} />}
                                                buttonContainerStyle={{
                                                    height: 40,
                                                    padding: ".75rem",
                                                    backgroundColor: "rgba(24, 29, 62, 0.1)",
                                                    borderRadius: 3
                                                }}
                                                labelStyle={{
                                                    color: "inherit",
                                                }}
                                                onClick={() => { }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}><span>Availability:&nbsp;</span><span style={{ justifyContent: "center", color: 'red', fontWeight: 500, cursor: 'not-allowed', backgroundColor: 'rgba(352, 96, 65, 0.1)', padding: '.5rem 1rem', userSelect: 'none', borderRadius: 5, display: 'inline-flex', alignItems: 'center' }}><HiBan size={18} /> &nbsp;Out of stock</span></div>}
                        </>
                    }
                </div>
                <div className="wa__btn-container d-none">
                    <a
                        href={`https://api.whatsapp.com/send?phone=917093134343&text=https://www.dentalstores.in/product/${product.slug}.html`}
                        className="wa__status-online"
                    >
                        <div className="wa__btn-icon">
                            <BsWhatsapp size={45} />
                        </div>
                        <div className="wa__btn-txt">
                            <div className="wa__cs-info">
                                <div className="wa__cs-name" style={{ color: "#d5f0d9", opacity: 1 }}>Bulk Order &amp; Special Price</div>
                                <div className="wa__cs-status">Online</div>
                            </div>
                            <div className="wa__btn-title" style={{ color: "#fff" }}>WhatsApp &amp; Chat with us</div>
                        </div>
                    </a>
                </div>
                
                <a
                    href={`https://api.whatsapp.com/send?phone=918520002490&text=https://www.dentalstores.in/product/${product.slug}.html`}
                    className="whatsapp__btn__container"
                >
                    <div className="whatsapp__icon">
                        <svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_3_8)">
                            <path d="M54.532 138.45L56.767 139.774C66.154 145.345 76.917 148.292 87.893 148.297H87.916C121.623 148.297 149.055 120.871 149.069 87.162C149.075 70.827 142.72 55.466 131.174 43.911C125.511 38.2124 118.774 33.6936 111.353 30.6163C103.932 27.5389 95.9739 25.9641 87.94 25.983C54.207 25.983 26.774 53.406 26.762 87.113C26.7454 98.623 29.9866 109.903 36.111 119.648L37.566 121.96L31.387 144.518L54.532 138.45ZM13.721 161.994L24.16 123.88C17.722 112.726 14.335 100.072 14.339 87.108C14.356 46.552 47.36 13.558 87.917 13.558C107.598 13.568 126.071 21.227 139.964 35.13C153.857 49.033 161.501 67.513 161.494 87.167C161.476 127.72 128.467 160.72 87.916 160.72H87.884C75.571 160.715 63.472 157.626 52.725 151.766L13.721 161.994Z" fill="#B3B3B3"/>
                            </g>
                            <path d="M12.966 161.238L23.405 123.124C16.9559 111.943 13.5684 99.2595 13.584 86.352C13.601 45.796 46.605 12.802 87.162 12.802C106.843 12.812 125.316 20.471 139.209 34.374C153.102 48.277 160.746 66.757 160.739 86.411C160.721 126.964 127.712 159.964 87.161 159.964H87.129C74.816 159.959 62.717 156.87 51.97 151.01L12.966 161.238Z" fill="white"/>
                            <path d="M87.184 25.227C53.451 25.227 26.018 52.65 26.006 86.357C25.9894 97.867 29.2306 109.147 35.355 118.892L36.81 121.205L30.631 143.763L53.777 137.694L56.012 139.018C65.399 144.589 76.162 147.535 87.138 147.541H87.161C120.868 147.541 148.301 120.115 148.314 86.406C148.339 78.3716 146.771 70.4119 143.699 62.9878C140.628 55.5638 136.114 48.8227 130.419 43.155C124.756 37.4563 118.019 32.9374 110.597 29.8601C103.176 26.7827 95.218 25.208 87.184 25.227Z" fill="url(#paint0_linear_3_8)"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.772 55.603C67.394 52.542 65.944 52.48 64.635 52.427L61.111 52.384C59.885 52.384 57.893 52.844 56.209 54.684C54.525 56.524 49.774 60.971 49.774 70.016C49.774 79.061 56.362 87.801 57.28 89.029C58.198 90.257 69.998 109.41 88.685 116.779C104.214 122.903 107.374 121.685 110.746 121.379C114.118 121.073 121.623 116.932 123.154 112.639C124.685 108.346 124.686 104.668 124.227 103.899C123.768 103.13 122.542 102.673 120.702 101.753C118.862 100.833 109.825 96.386 108.14 95.772C106.455 95.158 105.23 94.853 104.003 96.693C102.776 98.533 99.257 102.672 98.184 103.899C97.111 105.126 96.04 105.28 94.2 104.361C92.36 103.442 86.44 101.5 79.416 95.237C73.951 90.364 70.262 84.346 69.188 82.507C68.114 80.668 69.074 79.672 69.996 78.756C70.821 77.932 71.834 76.609 72.755 75.536C73.676 74.463 73.979 73.696 74.591 72.471C75.203 71.246 74.898 70.17 74.438 69.251C73.978 68.332 70.406 59.239 68.772 55.603Z" fill="white"/>
                            <defs>
                            <filter id="filter0_f_3_8" x="6.65901" y="6.496" width="161.897" height="162.56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="3.531" result="effect1_foregroundBlur_3_8"/>
                            </filter>
                            <linearGradient id="paint0_linear_3_8" x1="85.915" y1="32.567" x2="86.535" y2="137.092" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#57D163"/>
                            <stop offset="1" stop-color="#23B33A"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="whatsapp__text">WhatsApp live chat</div>
                </a>

                <div className="product__variation-section">
                    {
                        proVariations.length !== 0 ?
                            <>
                                {proVariations.map((pvar, index) =>
                                    <Variation mainPro={product} product={pvar} root={root} key={index} thumbnail={product.thumbnail} />
                                )}
                            </>
                            : null}
                </div>
                <div className="product__tabs-section">
                    <Collapse
                        defaultActiveKey={['0']}
                        accordion
                        bordered={false}
                        expandIcon={({ isActive }) => <BiChevronRight size={20} style={{ transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)' }} />}
                        className="accordion__container"
                    >
                        {!!tab ?
                            <>
                                {Object.keys(tab).length !== 0 ? Object.keys(tab).map((item, index) => (
                                    <Panel className="accordion__item" header={tab[item].title} key={index}>
                                        <div dangerouslySetInnerHTML={{ __html: tab[item].description }} />
                                    </Panel>
                                )) : null}
                            </>
                            : null}

                    </Collapse>
                </div>
                <div className="product__share-container">
                    <span style={{ fontWeight: 500 }}>Share : </span>
                    <div className="share__product-link">
                        <a href={`https://api.whatsapp.com/send?text=https://www.dentalstores.in/product/${product.slug}.html`}><BsWhatsapp /></a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.dentalstores.in/product/${product.slug}.html`}><BsFacebook /></a>
                        <a href={`https://twitter.com/share?url=https://www.dentalstores.in/product/${product.slug}.html`}><BsTwitter /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

const SimilarProducts = ({ id }) => {
    const [similar, setSimilar] = useState([]);

    useEffect(() => {
        axios.get(`${root}/api/product/similar/${id}`).then((response) => { setSimilar(response.data) });
    }, [id]);

    let regularPrice = null;
    let salePrice = null;
    const minmax = (product) => {
        regularPrice = product.pro_var.map(ver => ver.regular_price);
        salePrice = product.pro_var.map(ver => ver.sale_price);
    }

    // Navigation Prev/Next Ref
    const fb_NPR = useRef(null);
    const fb_NNR = useRef(null);

    return (
        <section className="section">
            <div className="product__srow-container">
                {similar.length > 0 ?
                    <h2 className="section__title">Similar Products<span>.</span></h2>
                    : null}
                <Swiper
                    grabCursor={true}
                    spaceBetween={10}
                    slidesPerView="auto"
                    navigation={{
                        prevEl: fb_NPR.current,
                        nextEl: fb_NNR.current,
                    }}
                    modules={[Navigation]}
                    className="similar__container"
                >
                    {similar.length > 0 ?
                        <>
                            {similar.map((product, index) => (
                                <SwiperSlide className="product__card" key={index}>
                                    <Link to={"/product/" + product.slug + ".html"}>
                                        <div className="product__card-img">
                                            <img src={root + product.thumbnail} alt={product.slug} />
                                        </div>
                                        <div className="product__data">
                                            <h2 className="product__price">
                                                {product.pro_var.length > 0 ?
                                                    <>
                                                        {minmax(product)}
                                                        <span className="starting">Starting at: </span>
                                                        <span>{regularPrice && salePrice ? formatAmount(Math.min.apply(Math, salePrice)) : formatAmount(Math.min.apply(Math, regularPrice))}</span>
                                                    </>
                                                    :
                                                    <>
                                                        {product.regular_price && product.sale_price ?
                                                            <>
                                                                <span>{formatAmount(product.sale_price)}</span>&nbsp;
                                                                <span className="mrp">{formatAmount(product.regular_price)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span>{formatAmount(product.regular_price)}</span>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </h2>
                                            <h3 className="product__title">{product.title.substring(0, 43)}{product.title.length >= 43 && '...'}</h3>
                                            <p className="product__description">{!!product.categories.length > 0 ? product.categories[0].name : null}</p>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </>
                        : null}
                    <div className="swiper-button-prev" ref={fb_NPR}>
                        <BiChevronLeft />
                    </div>
                    <div className="swiper-button-next" ref={fb_NNR}>
                        <BiChevronRight />
                    </div>
                </Swiper>
            </div>
        </section>
    )
}

const RelatedProducts = ({ id }) => {
    const [related, setRelated] = useState([]);

    useEffect(() => {
        axios.get(`${root}/api/product/related/${id}`).then((response) => { setRelated(response.data) });
    }, [id]);

    let regularPrice = null;
    let salePrice = null;
    const minmax = (product) => {
        regularPrice = product.pro_var.map(ver => ver.regular_price);
        salePrice = product.pro_var.map(ver => ver.sale_price);
    }

    // Navigation Prev/Next Ref
    const fb_NPR = useRef(null);
    const fb_NNR = useRef(null);

    return (
        <section className="section">
            <div className="product__srow-container">
                {related.length > 0 ?
                    <h2 className="section__title">Related Products<span>.</span></h2>
                    : null}
                <Swiper
                    grabCursor={true}
                    spaceBetween={10}
                    slidesPerView="auto"
                    navigation={{
                        prevEl: fb_NPR.current,
                        nextEl: fb_NNR.current,
                    }}
                    modules={[Navigation]}
                    className="related__container"
                >
                    {related.length > 0 ?
                        <>
                            {related.map((product, index) => (
                                <SwiperSlide className="product__card" key={index}>
                                    <Link to={"/product/" + product.slug + ".html"}>
                                        <div className="product__card-img">
                                            <img src={root + product.thumbnail} alt={product.slug} />
                                        </div>
                                        <div className="product__data">
                                            <h2 className="product__price">
                                                {product.pro_var.length > 0 ?
                                                    <>
                                                        {minmax(product)}
                                                        <span className="starting">Starting at: </span>
                                                        <span>{regularPrice && salePrice ? formatAmount(Math.min.apply(Math, salePrice)) : formatAmount(Math.min.apply(Math, regularPrice))}</span>
                                                    </>
                                                    :
                                                    <>
                                                        {product.regular_price && product.sale_price ?
                                                            <>
                                                                <span>{formatAmount(product.sale_price)}</span>&nbsp;
                                                                <span className="mrp">{formatAmount(product.regular_price)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span>{formatAmount(product.regular_price)}</span>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </h2>
                                            <h3 className="product__title">{product.title.substring(0, 43)}{product.title.length >= 43 && '...'}</h3>
                                            <p className="product__description">{!!product.categories.length > 0 ? product.categories[0].name : null}</p>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </>
                        : null}
                    <div className="swiper-button-prev" ref={fb_NPR}>
                        <BiChevronLeft />
                    </div>
                    <div className="swiper-button-next" ref={fb_NNR}>
                        <BiChevronRight />
                    </div>
                </Swiper>
            </div>
        </section>
    )
}

const SingleProduct = () => {

    let { pathname } = useLocation();
    pathname = pathname.split("/").pop();

    const { product, loader } = useSelector((state) => state.products);
    const { gallery, categories, brands, tabs, pro_var } = product;
    let regularPrice = '', salePrice = '', brand = '', tab = '', varLength = 0;
    const images = [], proVariations = [];


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProduct(pathname));
    }, [pathname, dispatch]);

    // All in one
    if (Object.keys(product).length !== 0) {
        // Gallery
        if (gallery.length !== 0) {
            gallery.map((img) => {
                return images.push(root + img.path);
            })
        } else {
            images.push(root + product.thumbnail);
        }
        // Variations
        if (pro_var.length !== 0) {
            pro_var.map((variation) => {
                return proVariations.push(variation);
            })
        }
        // Brand
        if (brands.length !== 0) {
            brands.map((b) => brand = b);
        }
        // Tabs
        tabs.map((tabsData) => tab = tabsData.product_tabs);
        // Price
        regularPrice = pro_var.map(ver => ver.regular_price);
        salePrice = pro_var.map(ver => ver.sale_price);
        varLength = pro_var.length;
    }

    return (
        <>
            {!loader ?
                <>
                    <div className="product__details-container">
                        <Gallery images={images} ptitle={product.title} />
                        <Details
                            product={product}
                            brand={brand}
                            categories={categories}
                            regularPrice={regularPrice}
                            salePrice={salePrice}
                            tab={tab}
                            varLength={varLength}
                            proVariations={proVariations}
                        />
                    </div>
                    <SimilarProducts id={product.id} />
                    <RelatedProducts id={product.id} />
                </>
                : null}
        </>
    )
}

export default SingleProduct;