import React from "react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import { HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Algolia } from "../../Constants";
import "./style.css";

import { dsinPath } from "../../Constants"; 
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const searchClient = algoliasearch(
    Algolia.ApplicationID,
    Algolia.AdminAPIKey
);

const Hits = ({ hits }) => {
    return (
        <>
            {hits.length > 0 ?
                <div className="all-categories-catalogue" >
                    {hits.map((hit, index) => (
                        <Link to={"/" + hit.slug +".html"} key={index}>
                            <div className="category__card-tag" key={hit.id}>
                                <span>{hit.name}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                : "Sorry, we couldn't find any results."}
        </>
    )
};

const CustomHits = connectHits(Hits);
const Content = () => <CustomHits />;

const SearchBox = ({ currentRefinement, refine }) => (
    <div className="search-bar-section">
        <div className="title">All Categories Catalogue.</div>
        <form noValidate action="" role="search" className="input-group">
            <input type="search" autoFocus placeholder="What is on your mind today?" className="input" value={currentRefinement} onChange={event => refine(event.currentTarget.value)} />
            <HiOutlineSearch className="icon" />
        </form>
    </div>
);

const CustomSearchBox = connectSearchBox(SearchBox);

const Categories = () => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${dsinPath}/categories`} />
                <meta name="keywords" content="Dental Products, Dental Products online, Buy Dental Products, Dental Products Price,Dental Products Online India, Dental Products Price In India" />
                {generateHeadTags({
                title: 'All Categories | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'All Categories | DentalStores.in',
                    url: `${dsinPath}/categories`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <InstantSearch indexName="dsin_category_index" searchClient={searchClient}>
                <CustomSearchBox />
                <Content />
            </InstantSearch>
        </>
    )
}

export default Categories;