import React from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

const InputSpinner = ({ 
    extraclass,
    minus,
    plus,
    qty
    }) => {
    return (
        <div className={`spinner__group ${extraclass}`}>
            <BiMinus className="spinner__minus" onClick={minus} />
            <span className="spinner__count">{qty}</span>
            <BiPlus className="spinner__plus" onClick={plus} />
        </div>
    )
}

export default InputSpinner;