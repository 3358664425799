import { createSlice } from "@reduxjs/toolkit";
import { getCategories, getCategory } from "./CategoryActions";

const CategoryReducers = createSlice({
    name: 'CategoryReducers',
    initialState: {
        categories: [],
        categoriesContainer: [],
        loader: false,
        errors: {},
        data: []
    },
    reducers: {
        filteredCategories: (state, action) => {
            state.categories = state.categoriesContainer.filter((category) => 
                category.name.toLowerCase().includes(action.payload)
            )
        }
    },
    extraReducers: {
        // All Categories
        [getCategories.pending]: (state) => {
            state.loader = true;
        },
        [getCategories.fulfilled]: (state, action) => {
            state.loader = false;
            state.categories = action.payload;
            state.categoriesContainer = action.payload;
        },
        [getCategories.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
        // Single Category
        [getCategory.pending]: (state) => {
            state.loader = true;
        },
        [getCategory.fulfilled]: (state, action) => {
            state.loader = false;
            state.data = action.payload;
        },
        [getCategory.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        }
    }
})

export const { filteredCategories } = CategoryReducers.actions;
export default CategoryReducers.reducer;