import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';

const BreadcrumbNavigation = ({path1, path2, link}) => {
    return (
        <Breadcrumb className="custom__breadcrumb-container">
            <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to={link}>{path1}</Link></Breadcrumb.Item>
            <Breadcrumb.Item style={{textTransform: "Capitalize"}}>{path2}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BreadcrumbNavigation;