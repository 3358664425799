import React from "react";

const Sorry = () => {
    return (
        <div style={{textAlign: "center", margin: "2rem auto", padding: 30, background: "#fff"}}>
            <h3>Sorry, we can't find any matches to your query!</h3>
            <p>We are still updating our product listings, please WhatsApp to <br />
               <b> +91 70931 34343</b> if you are unable to find the product here.
            </p>
        </div>
    )
}

export default Sorry;