import React, { useState, useEffect } from "react";
import { utils } from "../../utils";
import { FormInput, TextButton, OrderSummary } from "../../Components";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from 'antd';
import "./style.css";
import { SAddress } from "../../Redux/ShoppingCart/CartActions";
import { useDispatch } from "react-redux";

import { dsinPath } from "../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Shipping = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincod] = useState("");
    const [gstin, setGSTIN] = useState("");
    const [nameError, setNameError] = useState("");
    const [numberError, setNumberError] = useState("");
    const [addressError, setAddressError] = useState("");
    function isEnableButton() {
        return name !== "" && number !== "" && address !== "" && city !== "" && state !== "" && pincode !== "" && pincode.length === 6 &&
               nameError === "" && numberError === "" && addressError === ""
    }

    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!localStorage.getItem('auth_token')) navigate('/login');
        if (localStorage.getItem('address')) navigate('/checkout/payment');
    }, [navigate]);

    function capitalizeName(name) {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }

    const submit = (e) => {
        e.preventDefault();
        setLoad(true);

        const data = {
            name: name,
            mobile: number,
            address: address,
            city: city,
            state: state,
            pincode: pincode,
            country: "India",
            gstin: gstin,
            auth: localStorage.getItem('auth_email')
        }

        axios.post('api/sbaddress', data).then(res => {
            if (res.data.status === 200) {
                setLoad(false);
                dispatch(SAddress(data))
                localStorage.setItem('address', true);
                Modal.success({
                    title: "Your shipping address has been updated."
                })
                navigate("/checkout/payment")
            } else {
                setLoad(false);
                Modal.error({
                    title: "Something went wrong",
                    content: "Sorry, something went wrong there. Please try again."
                })
            }
        });
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'Shipping Address | DentalStores.in',
                description: 'Shop Dental supplies online at the lowest prices in India. Free Shipping on qualifying orders.',
                openGraph: {
                    title: 'Shipping Address | DentalStores.in',
                    url: `${dsinPath}/checkout/shipping`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <div className="shipping__address-container">
                <form onSubmit={submit} className="shipping__form-container">
                    <h1 className="section__title">Shipping Address</h1>
                    <div className="os__grid-2">
                        <FormInput
                            label="Fullname"
                            placeholder="for ex. Yourname"
                            keyboardType="text"
                            textInputStyles={{
                                borderRadius: "2rem",
                                border: "1px solid #e0e0e0",
                                padding: "0 20px",
                                marginBottom: "1rem"
                            }}
                            inputStyle={{
                                textTransform: "capitalize"
                            }}
                            onChange={(value) => {
                                // Validate Name
                                utils.validUsername(value.target.value, setNameError)
                                setName(capitalizeName(value.target.value))
                            }}
                            errorMsg={nameError}
                            appendComponent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {name === "" || (name !== "" && nameError === "") ?
                                        <FiCheckCircle size={20} color={name === "" || name.length < 5 ? "#808080" : "#00b878"} />
                                        : <FiXCircle size={20} color="#f35" />

                                    }
                                </div>
                            }
                        />
                        <FormInput
                            label="WhatsApp no."
                            placeholder="WhatsApp no."
                            keyboardType="text"
                            textInputStyles={{
                                borderRadius: "2rem",
                                border: "1px solid #e0e0e0",
                                padding: "0 20px",
                                marginBottom: "1rem"
                            }}
                            onChange={(value) => {
                                // Validate Number
                                utils.validNumber(value.target.value, setNumberError)
                                setNumber(value.target.value)
                            }}
                            errorMsg={numberError}
                            appendComponent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {number === "" || (number !== "" && numberError === "") ?
                                        <FiCheckCircle size={20} color={number === "" ? "#808080" : "#00b878"} />
                                        : <FiXCircle size={20} color="#f35" />
                                    }
                                </div>
                            }
                        />
                    </div>
                    <FormInput
                        label="Address"
                        placeholder="House-Clinic number/Building-Apartment/Locality"
                        keyboardType="text"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px",
                            marginBottom: "1rem"
                        }}
                        inputStyle={{
                            textTransform: "capitalize"
                        }}
                        onChange={(value) => {
                            // Validate Address
                            utils.validUsername(value.target.value, setAddressError)
                            setAddress(capitalizeName(value.target.value))
                        }}
                        errorMsg={addressError}
                        appendComponent={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {address === "" || (address !== "" && addressError === "") ?
                                    <FiCheckCircle size={20} color={address === "" || address.length < 5 ? "#808080" : "#00b878"} />
                                    : <FiXCircle size={20} color="#f35" />
                                }
                            </div>
                        }
                    />
                    <div className="os__grid-3">
                        <FormInput
                            label="City"
                            placeholder="ex. Hyderabad"
                            keyboardType="text"
                            textInputStyles={{
                                borderRadius: "2rem",
                                border: "1px solid #e0e0e0",
                                padding: "0 20px",
                                marginBottom: "1rem"
                            }}
                            inputStyle={{
                                textTransform: "capitalize"
                            }}
                            onChange={(value) => {
                                setCity(capitalizeName(value.target.value))
                            }}
                        />
                        <FormInput
                            label="State"
                            placeholder="ex. Telangana"
                            keyboardType="text"
                            textInputStyles={{
                                borderRadius: "2rem",
                                border: "1px solid #e0e0e0",
                                padding: "0 20px",
                                marginBottom: "1rem"
                            }}
                            inputStyle={{
                                textTransform: "capitalize"
                            }}
                            onChange={(value) => {
                                setState(capitalizeName(value.target.value))
                            }}
                        />
                        <FormInput
                            label="Pincode"
                            placeholder="ex. 500004"
                            keyboardType="text"
                            textInputStyles={{
                                borderRadius: "2rem",
                                border: "1px solid #e0e0e0",
                                padding: "0 20px",
                                marginBottom: "1rem"
                            }}
                            onChange={(value) => {
                                setPincod(value.target.value)
                            }}
                        />
                    </div>
                    <FormInput
                        label="GSTIN (optional)"
                        placeholder="ex. 22AAAAA0000A1Z5"
                        keyboardType="text"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px",
                            marginBottom: "1rem"
                        }}
                        inputStyle={{
                            textTransform: "uppercase"
                        }}
                        onChange={(value) => {
                            setGSTIN(value.target.value.toUpperCase())
                        }}
                    />
                    <TextButton
                        label={load ? "Please wait..." : "Save & Next"}
                        buttonContainerStyle={{
                            height: 40,
                            width: 120,
                            marginTop: "2rem",
                            fontSize: ".85rem",
                            backgroundColor: isEnableButton() ? "hsl(232, 44%, 17%)" : 'rgba(0,0,0,0.2)'
                        }}
                        dis={!isEnableButton() || load ? true : false}
                    />
                </form>
                <OrderSummary />
            </div>
        </>
    )
}

export default Shipping;