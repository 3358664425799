import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Breadcrumb, Spin, Modal } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { DiDropbox } from "react-icons/di";
import { BiLogOut, BiCloudDownload } from "react-icons/bi";
import { HiOutlineHeart, HiUser, HiOutlineLocationMarker } from "react-icons/hi";
import "./style.css";

import { dsinPath } from "../../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Dashboard = () => {

    const navigate = useNavigate();
    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('auth_token')) navigate('/login');
    }, [navigate]);

    const logoutSubmit = (e) => {
        e.preventDefault();
        setLoad(true);

        axios.post('/api/logout').then(res => {
            setLoad(false);
            if(res.data.status === 200)
            {
                localStorage.removeItem('auth_token', res.data.token);
                localStorage.removeItem('auth_name', res.data.name);
                localStorage.removeItem('auth_email', res.data.email);
                localStorage.removeItem('auth_details', res.data.auth);
    
                Modal.success({
                    title: 'Logged out successfully',
                    afterClose: () => window.location.reload()
                });
    
                navigate('/');
            }
        });
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'My Account | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'My Account | DentalStores.in',
                    url: `${dsinPath}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            {load ? <Spin tip="Please wait..." className="spin__loading" /> : 
            <div className="myaccount">
                <Breadcrumb className="myaccount__breadcrumb-container">
                    <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
                    <Breadcrumb.Item>My Account</Breadcrumb.Item>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
                <div className="dashboard__section">
                    <p className="ac__heading">Hello <span className="name">{localStorage.getItem('auth_name')}</span> (not <span className="name">{localStorage.getItem('auth_email')}</span>? <Link to="#" className="link" onClick={logoutSubmit}>Log out</Link>)</p>
                    <p className="ac__subtitle">From your account dashboard you can view your <Link to="#" className="link">recent orders</Link>, manage your <Link to="#" className="link">shipping and billing addresses</Link>, and <Link to="#" className="link">edit your password and account details.</Link></p>
                    <div className="ac__section-grid">
                        <Link to="/account/orders" className="box-item-group">
                            <DiDropbox className="icon" />
                            <span>Orders</span>
                        </Link>
                        <Link to="" className="box-item-group">
                            <BiCloudDownload className="icon" />
                            <span>Downloads</span>
                        </Link>
                        <Link to="/account/address" className="box-item-group">
                            <HiOutlineLocationMarker className="icon" />
                            <span>Address</span>
                        </Link>
                        <Link to="/account/details" className="box-item-group">
                            <HiUser className="icon" />
                            <span>Account Details</span>
                        </Link>
                        <Link to="/account/wishlist" className="box-item-group">
                            <HiOutlineHeart className="icon" />
                            <span>Wishlist</span>
                        </Link>
                        <div className="box-item-group" onClick={logoutSubmit}>
                            <BiLogOut className="icon" />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Dashboard;