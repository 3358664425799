import React, { useRef, useState, useEffect } from "react";
import currencyFormatter from "currency-formatter";
import TextButton from "./TextButton";
import { HiShoppingCart, HiOutlineHeart, HiBan } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { AddToCart } from "../Redux/ShoppingCart/CartActions";

import { useSelector } from "react-redux";

// Price Section
const formatAmount = (price) => {
    return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
}

const Variation = ({ mainPro, product, thumbnail, root }) => {

    const dispatch = useDispatch();
    const [qty, setQty] = useState('');
    const inputRef = useRef();
    const AddQty = () => {
        setQty(parseInt(parseInt(inputRef.current.value)))
    }

    const [apprel, setApprel] = useState(false);
    const { products } = useSelector((state) => state.shoppingcart);

    useEffect(() => {
        if (products.find(e => e.brand === "DS Apparels")) {
            setApprel(true);
        } else {
            setApprel(false);
        }
    }, [products]);

    return (
        <>
            <div className="var__container">
                {/* <img src={root + thumbnail} alt={product.slug} className="var__img" width={100} height={100} /> */}
                <div style={{ marginLeft: 10, width: "100%" }}>
                    <p className="var__title">{product.variation_desc}</p>
                    <div className="var__pqty">
                        <div>
                            {product.regular_price && product.sale_price ?
                                <>
                                    <span className="sale__price">{formatAmount(product.sale_price)}</span>
                                    <span className="mrp__price">{formatAmount(product.regular_price)}</span>
                                    <span className="off">({parseInt((product.regular_price - product.sale_price) / (product.regular_price) * 100)}% Off)</span>
                                    <div className="you__save">You save: {formatAmount(product.regular_price - product.sale_price)}/- (incl. of all taxes)</div>
                                </>
                                : <><span className="sale__price">{formatAmount(product.regular_price)}</span>{product.dfee !== null ? <span className="sale__price" style={{ color: "#0d6efd", fontSize: "0.8rem" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null}</>
                            }
                        </div>
                        {/* Quantity */}
                        {mainPro.stock_status === "In stock" ?
                            <>
                                {product.stock_status === "In stock" ?
                                    <>
                                        {product.variation_desc === "Customised Logo" ?
                                            <>
                                                {apprel ?
                                                    <div className="quantity__container">
                                                        <input type="number" min={1} onChange={AddQty} ref={inputRef} />
                                                        <div className="qty__action-btns">
                                                            <TextButton
                                                                label="Add to cart"
                                                                iconLeft={<HiShoppingCart color="white" size={20} style={{ marginRight: 5 }} />}
                                                                buttonContainerStyle={{
                                                                    height: 40,
                                                                    padding: ".75rem",
                                                                    // backgroundColor: "hsl(352, 96%, 65%)",
                                                                    borderRadius: 3
                                                                }}
                                                                onClick={() => dispatch(AddToCart(product, qty))}
                                                            />
                                                            <TextButton
                                                                label="Wishlist"
                                                                iconLeft={<HiOutlineHeart size={20} style={{ marginRight: 5 }} />}
                                                                buttonContainerStyle={{
                                                                    height: 40,
                                                                    padding: ".75rem",
                                                                    backgroundColor: "rgba(24, 29, 62, 0.1)",
                                                                    borderRadius: 3
                                                                }}
                                                                labelStyle={{
                                                                    color: "inherit",
                                                                }}
                                                                onClick={() => { }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : <span style={{color: "red", fontSize: ".85rem"}}>Please select a size to continue</span>}
                                            </>
                                            :
                                            <div className="quantity__container">
                                                <input type="number" min={1} onChange={AddQty} ref={inputRef} />
                                                <div className="qty__action-btns">
                                                    <TextButton
                                                        label="Add to cart"
                                                        iconLeft={<HiShoppingCart color="white" size={20} style={{ marginRight: 5 }} />}
                                                        buttonContainerStyle={{
                                                            height: 40,
                                                            padding: ".75rem",
                                                            // backgroundColor: "hsl(352, 96%, 65%)",
                                                            borderRadius: 3
                                                        }}
                                                        onClick={() => dispatch(AddToCart(product, qty))}
                                                    />
                                                    <TextButton
                                                        label="Wishlist"
                                                        iconLeft={<HiOutlineHeart size={20} style={{ marginRight: 5 }} />}
                                                        buttonContainerStyle={{
                                                            height: 40,
                                                            padding: ".75rem",
                                                            backgroundColor: "rgba(24, 29, 62, 0.1)",
                                                            borderRadius: 3
                                                        }}
                                                        labelStyle={{
                                                            color: "inherit",
                                                        }}
                                                        onClick={() => { }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                    : <span style={{ color: 'red', fontWeight: 500, cursor: 'not-allowed', backgroundColor: 'rgba(352, 96, 65, 0.1)', padding: '.5rem 1rem', userSelect: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: "center" }}><HiBan size={18} /> &nbsp;Out of stock</span>}
                            </>
                            : <span style={{ color: 'red', fontWeight: 500, cursor: 'not-allowed', backgroundColor: 'rgba(352, 96, 65, 0.1)', padding: '.5rem 1rem', userSelect: 'none', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: "center" }}><HiBan size={18} /> &nbsp;Out of stock</span>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Variation;