import React from "react";
import { useEffect } from "react";
import { root, dsinPath } from "../../Constants";
import { useLocation } from "react-router";
import currencyFormatter from "currency-formatter";
import { useSelector, useDispatch } from "react-redux";
import { getBrand } from "../../Redux/Brands/BrandActions";
import { Link } from "react-router-dom";
import { BreadcrumbNavigation, Sorry } from "../../Components";

import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const BrandShop = () => {
    let { pathname } = useLocation();
    pathname = pathname.split("/").pop();

    let cusPath = pathname.split(".").slice(0, -1).join('.');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBrand(pathname));
    }, [pathname, dispatch]);

    const { loader, data } = useSelector((state) => state.brands);

    // Format Amount
    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }
    let regularPrice = null;
    let salePrice = null;
    const minmax = (product) => {
        regularPrice = product.pro_var.map(ver => ver.regular_price);
        salePrice = product.pro_var.map(ver => ver.sale_price);
    }


    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${dsinPath}/${pathname}`} />
                <meta name="keywords" content="Dental Products, Dental Products online, Buy Dental Products, Dental Products Price,Dental Products Online India, Dental Products Price In India" />
                {generateHeadTags({
                title: `${cusPath} | DentalStores.in`,
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: `${cusPath} | DentalStores.in`,
                    url: `${dsinPath}/${pathname}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <BreadcrumbNavigation path1="Brands" path2={cusPath} link="/brands" />
            {data.length > 0 ?
            <section className="product__grid-section">
                {!loader ? data.map((product, index) =>
                    <div className="product__card" key={index}>
                        <Link to={"/product/" + product.slug + ".html"}>
                        <div className="product__card-img">
                            <img src={root + product.thumbnail} alt={product.slug} />
                        </div>
                        <div className="product__data">
                            <h2 className="product__price">
                                {product.pro_var.length > 0 ?
                                    <>
                                        {minmax(product)}
                                        <span className="starting">Starting at: </span>
                                        <span>{regularPrice && salePrice ? formatAmount(Math.min.apply(Math, salePrice)) : formatAmount(Math.min.apply(Math, regularPrice))}</span>
                                    </>
                                    :
                                    <>
                                        {product.regular_price && product.sale_price ?
                                            <>
                                                <span>{formatAmount(product.sale_price)}</span>&nbsp;
                                                <span className="mrp">{formatAmount(product.regular_price)}</span>
                                            </>
                                            :
                                            <>
                                                <span>{formatAmount(product.regular_price)}</span>
                                            </>
                                        }
                                    </>
                                }
                            </h2>
                            <h3 className="product__title">{product.title.substring(0, 43)}{product.title.length >= 43 && '...'}</h3>
                            <p className="product__description">{!!product.categories.length > 0 ? product.categories[0].name : null}</p>
                        </div>
                        </Link>
                    </div>
                ) : 'loading...'}
            </section>
            : <Sorry /> }
        </>
    )
}

export default BrandShop;