import React from "react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import { HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import { root, dsinPath } from "../../Constants";
import { Algolia } from "../../Constants";

import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const searchClient = algoliasearch(
    Algolia.ApplicationID,
    Algolia.AdminAPIKey
);

const Hits = ({ hits }) => {
    // Format Amount
    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }
    let regularPrice = null;
    let salePrice = null;
    const minmax = (product) => {
        regularPrice = product.pro_var.map(ver => ver.regular_price);
        salePrice = product.pro_var.map(ver => ver.sale_price);
    }

    return (
        <>
            {hits.length > 0 ?
                <section className="product__grid-section">
                {hits.map((product, index) =>
                    <div className="product__card" key={index}>
                        <Link to={"/product/" + product.slug + ".html"}>
                        <div className="product__card-img">
                            <img src={root + product.thumbnail} alt={product.slug} />
                        </div>
                        <div className="product__data">
                            <h2 className="product__price">
                                {product.pro_var.length > 0 ?
                                    <>
                                        {minmax(product)}
                                        <span className="starting">Starting at: </span>
                                        <span>{regularPrice && salePrice ? formatAmount(Math.min.apply(Math, salePrice)) : formatAmount(Math.min.apply(Math, regularPrice))}</span>
                                    </>
                                    :
                                    <>
                                        {product.regular_price && product.sale_price ?
                                            <>
                                                <span>{formatAmount(product.sale_price)}</span>&nbsp;
                                                <span className="mrp">{formatAmount(product.regular_price)}</span>
                                            </>
                                            :
                                            <>
                                                <span>{formatAmount(product.regular_price)}</span>
                                            </>
                                        }
                                    </>
                                }
                            </h2>
                            <h3 className="product__title">{product.title.substring(0, 43)}{product.title.length >= 43 && '...'}</h3>
                            <p className="product__description">{!!product.categories.length > 0 ? product.categories[0].name : null}</p>
                        </div>
                        </Link>
                    </div>
                )}
            </section>
                : "Sorry, we couldn't find any results."}
        </>
    )
};

const CustomHits = connectHits(Hits);
const Content = () => <CustomHits />;

const SearchBox = ({ currentRefinement, refine }) => (
    <div className="search-bar-section">
        <div className="title">What is on your mind today?</div>
        <form noValidate action="" role="search" className="input-group">
            <input type="search" autoFocus placeholder="Search for products, brands and more..." className="input" value={currentRefinement} onChange={event => refine(event.currentTarget.value)} />
            <HiOutlineSearch className="icon" />
        </form>
    </div>
);

const CustomSearchBox = connectSearchBox(SearchBox);

const Search = () => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${dsinPath}/search`} />
                <meta name="keywords" content="Dental Products, Dental Products online, Buy Dental Products, Dental Products Price,Dental Products Online India, Dental Products Price In India" />
                {generateHeadTags({
                title: 'Search Products | DentalStores.in',
                description: 'Search for products, brands and more...',
                openGraph: {
                    title: 'Search Products',
                    url: `${dsinPath}/search`,
                    'article:author': 'DentalStores.in',
                    'article:tag': ['dental', 'online store', 'dentalstores.in', 'dental products online'],
                },
                })}
            </Helmet>
            <InstantSearch indexName="dsin_product_index" searchClient={searchClient}>
                <CustomSearchBox />
                <Content />
            </InstantSearch>
        </>
    )
}

export default Search;