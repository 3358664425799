import React from "react";
import { BreadcrumbNavigation } from "../Components";
import "./style.css";

import { dsinPath } from "../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const About = () => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                {generateHeadTags({
                title: 'About Us | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'About Us | DentalStores.in',
                    url: `${dsinPath}/about-us`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <BreadcrumbNavigation path1="About Us" link="" />
            <div className="about__container">
                <p style={{ fontWeight: 300, fontSize: '1.5rem', marginTop: 10 }}>ABOUT US</p>
                <p>
                    DentalStores.in is an online supply chain solution for Clinical / Lab supplies in India for quality dental products. At DentalStores.in, we help you look after your dental practices effortlessly as well as take care of your patients. You can buy dental materials from any corner of the nation - with just a few clicks of the mouse. You can find thousand of dental products of all specialities including dental materials, instruments, equipments, disposables of all major international manufacturers around the globe. We also have a wide range of dental laboratory including equipments and consumable materials.
                </p>
                <p>
                    All the products prices includes GST Tax. DentalStores.in customers will have a price advantage as we try to supply the products at the least prices. We also assure of 100% Genuine Products on our portal.
                </p>
                <p>
                    DentalStores.in nationwide delivery capabilities, high-quality customer service support and scalable technology infrastructure enabled DentalStores.in to provide a compelling online shopping experience to its customers.
                </p>
                <div>
                    <p><u>DentalStores.in Objectives</u></p>
                    <ul>
                        <li>To supply products of the highest quality to the dental profession.</li>
                        <li>To offer a second-to none- service.</li>
                        <li>To provide the end-user with professional and pertinent product information and education.</li>
                        <li>To carry a full stocks of products and provide constant and reliable on-time deliveries.</li>
                        <li>To maintain close, professional and friendly communication with the dental profession.</li>
                        <li>To seek out and provide state-of-the-art products.</li>
                        <li>To maintain a level of expertise to ensure innovative and pro-active marketing strategies and techniques.</li>
                        <li>To continually service and grow contacts with all leading Indian dental universities, dental hospitals and dental institutions.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default About;