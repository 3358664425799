import React,  { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from 'antd';
import cartImg from "./cempty.png";
import { root, dsinPath } from "../../Constants";
import { BiError } from "react-icons/bi";
import currencyFormatter from "currency-formatter";
import { useSelector, useDispatch } from "react-redux";
import { HiArrowNarrowRight, HiXCircle } from "react-icons/hi";
import { FaShippingFast } from "react-icons/fa";
import { InputSpinner, TextButton, FormInput } from "../../Components";
import { IncQty, DecQty, RemovePro, Delivery, GrandTotal, CouponAc } from "../../Redux/ShoppingCart/CartActions";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Cart = () => {

    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }
    const { products, totalQtys, totalPrice, overWeight, delivery, couponData, grandTotal } = useSelector((state) => state.shoppingcart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [coupon, setCoupon] = useState('');

    
    useEffect(() => {
        function couponValidation () {
            if(localStorage.getItem('coupon')) {
                const data = { code: localStorage.getItem('coupon'), subtotal: totalPrice}
                axios.post(`api/coupon`, data).then(res => {
                    setLoad(false);
                    if (res.data.status === 200) {
                        dispatch(CouponAc({code: res.data.code, value: res.data.value}));
                    } else if (res.data.status === 404) {
                        Modal.error({
                            title: "Something Went Wrong",
                            content: res.data.message
                        });
                        dispatch(CouponAc({}));
                        localStorage.removeItem('coupon')
                    }
                })
            }
        }
        couponValidation();
    }, [dispatch, totalPrice])
    
    // Delivery Charges Start
    let codeC = couponData.value ? Number(couponData.value) : 0;
    if ((totalPrice - codeC) >= 2500) {
        dispatch(Delivery(0));
        //  couponValidation();
         if (localStorage.getItem('coupon')) {
             dispatch(GrandTotal(totalPrice - couponData.value));
         } else {
             dispatch(GrandTotal(totalPrice + delivery));
         }
    } else {
        if(overWeight !== 0) {
            dispatch(Delivery(0));
        } else {
            dispatch(Delivery(125));
        }
        //  couponValidation();
         if (localStorage.getItem('coupon')) {
             dispatch(GrandTotal(totalPrice + delivery - couponData.value));
         } else {
             dispatch(GrandTotal(totalPrice + delivery));
         }
    }
    // Delivery Charges End
    
    // Promo & Gift code 
    const submit = (e) => {
        e.preventDefault();
        setLoad(true)

        const data = { code: coupon.toUpperCase(), subtotal: totalPrice}

        if(localStorage.getItem('auth_token')) {
            axios.post(`api/coupon`, data).then(res => {
                setLoad(false);
                if (res.data.status === 200) {
                    Modal.success({
                        title: res.data.message,
                        content: `The ${coupon.toUpperCase()} promotion code has been applied and redeemed successfully.`
                    });
                    dispatch(CouponAc({code: res.data.code, value: res.data.value}));
                    localStorage.setItem('coupon', coupon);
                } else if (res.data.status === 404) {
                    Modal.error({
                        title: "Something Went Wrong",
                        content: res.data.message
                    });
                }
            })
        } else { navigate('/login') }


    }

    const RemovePromo = () => {
        dispatch(CouponAc({}))
        localStorage.removeItem('coupon')
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'My Shopping Cart | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'My Shopping Cart | DentalStores.in',
                    url: `${dsinPath}/cart`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <div className="shopping__cart-container">
                <h1 className="shopping__cart-heading">My Shopping Cart ({totalQtys})</h1>
                <div className="shopping__cart-body">
                    {products.length > 0 ?
                        <div>
                            {products.map((product, index) =>
                                <div className="shopping__cart-card" key={index}>
                                    <div className="shopping__cart-card__body">
                                        <div className="shopping__cart-img__container">
                                            <img src={root + product.thumbnail} alt={product.slug} className="sc__card-img" />
                                            <div className="sc__item__remove" onClick={() => dispatch(RemovePro(product.id))}>
                                                <HiXCircle />
                                            </div>
                                        </div>
                                        <div className="sc__card-main">
                                            <span className="sc__card-title">{product.title !== "variable" ? product.title : product.variation_desc}</span>
                                            {product.regular_price && product.sale_price ?
                                                <>
                                                    <span className="sc__card-price">{formatAmount(product.sale_price * product.quantity)}</span>
                                                    <span className="sc__card-subprice">({formatAmount(product.sale_price)} x {product.quantity})</span>
                                                    &nbsp; {product.dfee > 0 ? <span className="sc__card-price" style={{ color: "#0d6efd", fontSize: "0.8rem" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null}
                                                </>
                                                :
                                                <>
                                                    <span className="sc__card-price">{formatAmount(product.regular_price * product.quantity)}</span>
                                                    <span className="sc__card-subprice">({formatAmount(product.regular_price)} x {product.quantity})</span>
                                                    &nbsp; {product.dfee > 0 ? <span className="sc__card-price" style={{ color: "#0d6efd", fontSize: "0.8rem" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null}
                                                </>
                                            }
                                            {product.dfee > 0 ?
                                                <p style={{ fontSize: '0.75rem', fontWeight: 500, color: 'red', marginTop: 10 }}><BiError /> This product is not eligible for cash on delivery</p>
                                                : null}
                                        </div>
                                        <div className="sc__card-spinner">
                                            <InputSpinner
                                                minus={() => dispatch(DecQty(product.id))}
                                                plus={() => dispatch(IncQty(product.id))}
                                                qty={product.quantity}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        : <Empty />}
                    <div>
                        <div className="shopping__cart-price__card">
                            <h3 className="sc__pc-title">Price Details</h3>
                            <div className="sc__pc-item">
                                <span>Total Quantity</span>
                                <span>{totalQtys}</span>
                            </div>
                            <div className="sc__pc-item">
                                <span>Sub Total</span>
                                {overWeight > 0 ?
                                    <span>{formatAmount(totalPrice - overWeight)}</span>
                                    : <span>{formatAmount(totalPrice)}</span>
                                }
                            </div>
                            {overWeight > 0 ?
                                <div className="sc__pc-item">
                                    <span>Overweight delivery charges</span>
                                    <span>{formatAmount(overWeight)}</span>
                                </div>
                            : null}
                            {couponData.value ?
                            <div className="sc__pc-item" style={{color: "#3bb896", fontWeight: 500}}>
                                <span>Discount {couponData.code ? `(${couponData.code}) ` : null} 
                                    {couponData.code ? <div style={{cursor: 'pointer', fontSize: 10, color: 'red', textDecoration: 'underline', display: 'inline'}} onClick={() => RemovePromo() }>Remove</div>  : null} 
                                </span>
                                <span>-{formatAmount(couponData.value)}</span>
                            </div> 
                            : null }
                            <div className="sc__pc-item">
                                <span>Delivery Charges</span>
                                <span>{formatAmount(delivery)}</span>
                            </div>
                            <div className="sc__pc-item grand">
                                <span>Grand Total <span style={{fontSize: 11, fontWeight: 400}}>(Inclusive of all taxes)</span></span>
                                <span>{formatAmount(grandTotal)}</span>
                            </div>
                            <TextButton
                                label="Proceed to checkout"
                                iconRight={<HiArrowNarrowRight color="white" size={20} style={{ marginLeft: 5 }} />}
                                buttonContainerStyle={{
                                    height: 50,
                                    flex: 1,
                                    borderRadius: 3,
                                    backgroundColor: products.length > 0 ? "hsl(232, 44%, 17%)" : 'rgba(0,0,0,0.2)'
                                }}
                                onClick={() => products.length > 0 ? navigate('/checkout/shipping') : {} }
                                dis={!products.length > 0 ? true : false}
                            />
                        </div>
                        <div className="shooping__cart-value">
                                <FaShippingFast />
                                <span>&nbsp;&nbsp;Free delivery with cart value above ₹2,500/-.</span>
                        </div>
                        <div className="shopping__cart-coupon__container">
                            <h3 className="sc__coupon-title">Promos & Gift Codes</h3>
                            <p className="sc__coupon-subtitle"><span>Note:</span> One discount can be redeemed per order. See terms for details.</p>
                            <form onSubmit={submit} className="sc__form-container">
                                <FormInput
                                    placeholder="PROMO OR GIFT CODE"
                                    keyboardType="text"
                                    rqd={true}
                                    textInputStyles={{
                                        height: 40,
                                        marginTop: 0,
                                        border: "1px solid #e0e0e0",
                                        padding: "0 20px",
                                    }}
                                    inputStyle={{
                                        textTransform: "uppercase"
                                    }}
                                    onChange={(value) => {
                                        setCoupon(value.target.value);
                                    }}
                                />
                                <TextButton
                                    label={load ? "Please wait..." : "Apply"}
                                    buttonContainerStyle={{
                                        height: 40,
                                        width: 120,
                                        fontSize: ".85rem",
                                        backgroundColor: products.length > 0 ? "hsl(232, 44%, 17%)" : 'rgba(0,0,0,0.2)'
                                    }}
                                    dis={!products.length > 0 ? true : false}
                                />
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Cart;



const Empty = () => {
    return (
        <div className="shopping__cart-empty">
            <img src={cartImg} alt="emptycart" />
            <h3 className="section__title">Your cart is empty</h3>
            <p>Looks like you have not added anything to your cart.<br />Go ahead & explore <Link to="/search" style={{textDecoration: "underline", color: "inherit", fontWeight: 500}}>top brands.</Link></p>
        </div>
    )
}