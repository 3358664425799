import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getOrders = createAsyncThunk('orders', async (query) => {
    // orders/pending  |  orders/fullfilled  |  orders/rejected
    const response = await axios.request({
                        method: 'GET',
                        url: `/api/orders`,
                     });
    return response.data;
});
