import React, { useEffect } from "react";
import { Breadcrumb } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';
import { HiOutlineHeart } from "react-icons/hi";

import { dsinPath } from "../../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Wishlist = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('auth_token')) navigate('/login');
    }, [navigate]);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'Wishlist | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'Wishlist | DentalStores.in',
                    url: `${dsinPath}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <div className="myaccount">
                <Breadcrumb className="myaccount__breadcrumb-container">
                    <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to="/account">My Account</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Wishlist</Breadcrumb.Item>
                </Breadcrumb>
                <div className="dashboard__section">
                    <p className="ac__section-title"><HiOutlineHeart size={25} /> Wishlist</p>
                    <p>You have no items in your List.</p>
                </div>
            </div>
        </>
    )
}

export default Wishlist;