import React from "react";
import { Link } from "react-router-dom";
import { TextButton } from "../../Components";
import { HiHome } from "react-icons/hi";
import "./style.css"

import { dsinPath } from "../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const NotFound = () => {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: '404 - PAGE NOT FOUND | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: '404 - PAGE NOT FOUND | DentalStores.in',
                    url: `${dsinPath}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <div className="not__found-container">
                <h1 className="title">Oops!</h1>
                <h2 className="subtitle">404 - Page not found</h2>
                <p className="description">
                    The page you are looking for might have been removed <br />
                    had its name changed or is temporarily unavailable.
                </p>
                <Link to="/">
                    <TextButton
                        label="Go To Homepage"
                        iconLeft={<HiHome color="white" size={15} style={{ marginRight: 5 }} />}
                        buttonContainerStyle={{
                            height: 50,
                            padding: "0 1.5rem",
                            borderRadius: 100,
                            fontSize: ".8rem",
                            background: "hsl(352, 96%, 65%)",
                        }}
                        labelStyle={{
                            textTransform: "uppercase"
                        }}
                        onClick={() => { }}
                    />
                </Link>
            </div>
        </>
    )
}

export default NotFound;