import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const Order = () => {

    const navigate = useNavigate();
    let { pathname } = useLocation();
    pathname = pathname.split("/").pop();

    useEffect(() => {
        if (!localStorage.getItem('auth_token')) navigate('/login');
        if(pathname.length !== 19) {
            navigate(-1)
        }
    }, [navigate, pathname]);

    return (
        <h1>Order Id</h1>
    )
}

export default Order;