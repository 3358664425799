import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaFacebookF, FaInstagram, FaTwitter, FaPinterestP, FaYoutube, FaAngleDoubleUp } from "react-icons/fa";


function Footer() {

    useEffect(() => {
        /*=============== SHOW SCROLL UP ===============*/ 
        function scrollUp(){
            const scrollUp = document.getElementById('scroll-up');
            // When the scroll is higher than 350 viewport height, add the show-scroll class to the a tag with the scroll-top class
            if(this.scrollY >= 350) scrollUp.classList.add('show-scroll'); else scrollUp.classList.remove('show-scroll')
        }
        window.addEventListener('scroll', scrollUp);
    }, [])

    return (
        <>
            {/* <!--==================== FOOTER ====================--> */}
            <footer className="footer section">
                <div className="footer__container container grid">
                    <div className="footer__content">
                        <div className="footer__newslatter">
                            <h3 className="footer__title">LET'S KEEP IN TOUCH!</h3>
                            <p className="footer__description">
                                Sign up for exclusive deals and promos.
                            </p>
                            <div className="footer__search">
                                <input type="text" placeholder="Enter your email..." className="footer__search-input" />
                                <div className="icon"><FaTelegramPlane /></div>
                            </div>

                            <div className="footer__social-media">
                                <h3 className="footer__title">Follow us</h3>
                                <div className="footer__social">
                                    <a href="https://www.facebook.com/DentalStoresindia" className="footer__social-link">
                                        <FaFacebookF />
                                    </a>
                                    <a href="https://www.instagram.com/dentalstores_in/" className="footer__social-link">
                                        <FaInstagram />
                                    </a>
                                    <a href="https://twitter.com/DentalStores_in" className="footer__social-link">
                                        <FaTwitter />
                                    </a>
                                    <a href="https://in.pinterest.com/dentalstores_in/" className="footer__social-link">
                                        <FaPinterestP />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCUxYc4GLKjUt0Mf9Cf8umNg" className="footer__social-link">
                                        <FaYoutube />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className="footer__title">NEED TO CHAT?</h3>
                            <div className="footer__items">
                                <span>WhatsApp no: +91 70931 34343</span>
                                <span>Email: support@dentalstores.in</span>
                            </div>
                            <hr className="footer__hr" />
                            <div className="footer__items">
                                <span>WORKING DAYS/HOURS:</span>
                                <span>Mon - Sat / 10:30am - 7:00pm</span>
                            </div>
                        </div>
                        <div>
                            <h3 className="footer__title">ORDERS & RETURNS</h3>
                            <div className="footer__items">
                                <Link to="/account" className="footer__item-link">Order Status</Link>
                                <Link to="/help-desk" className="footer__item-link">Shipping Information</Link>
                                <Link to="/help-desk" className="footer__item-link">Return Policy</Link>
                                <Link to="/help-desk" className="footer__item-link">FAQs</Link>
                            </div>
                        </div>
                        <div>
                            <h3 className="footer__title">COMPANY</h3>
                            <div className="footer__items">
                                <Link to="/about-us" className="footer__item-link">About Us</Link>
                                <Link to="/" className="footer__item-link">Contact Us</Link>
                                <Link to="/" className="footer__item-link">Careers</Link>
                                <Link to="/" className="footer__item-link">Blogs</Link>
                            </div>
                        </div>

                    </div>
                    <div className="footer__info container">
                        <span className="footer__copy">© 2018-2022 Copyright DentalStores.in</span>

                        <div className="footer__privacy">
                            <Link to="/help-desk">Terms & Agreements</Link>
                            <Link to="/help-desk">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!--==================== SCROLL UP ====================--> */}
            <a href="#" className="scrollup" id="scroll-up">
                <FaAngleDoubleUp />
                <span style={{fontSize: 10}}>Go to top</span>
            </a>
        </>
    )
}

export default Footer