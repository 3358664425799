import React from "react";

const TextButton = ({
    buttonContainerStyle,
    label,
    title,
    labelStyle,
    iconLeft,
    iconRight,
    onClick,
    dis=false,
    cls
}) => {
    return (
        <button 
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: "hsl(232, 44%, 17%)",
                width: '100%',
                ...buttonContainerStyle
            }}
            onClick={onClick}
            title={title}
            disabled={dis}
            className={cls}
        >
            {iconLeft}
            <span style={{
                color: "hsl(0, 0%, 100%)",
                fontWeight: 500,
                userSelect: "none",
                ...labelStyle
            }}>
                {label}
            </span>
            {iconRight}
        </button>
    )
}

export default TextButton;