import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCategories = createAsyncThunk('categories', async () => {
    // categories/pending  |  categories/fullfilled  |  categories/rejected
    const response = await axios.request({
                                method: 'GET',
                                url: '/api/allcategories',
                            });
    return response.data;
});

export const getCategory = createAsyncThunk('category', async (slug) => {
    // category/pending  |  category/fullfilled  |  category/rejected
    const response = await axios.request({
                        method: 'GET',
                        url: `/api/category/${slug}`,
                     });
    return response.data;
})