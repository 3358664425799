import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBrands = createAsyncThunk('brands', async () => {
    // brands/pending | brands/fullfilled | brands/rejected
    const response = await axios.request({
                                    method: 'GET',
                                    url: '/api/brands'
                                });
    return response.data;
});

export const getBrand = createAsyncThunk('brand', async (slug) => {
    // brand/pending | brand/fullfilled | brand/rejected
    const  response = await axios.request({
                                    method: 'GET',
                                    url: `/api/brand/${slug}`
                                });
    return response.data;
});