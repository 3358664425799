import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "./UserActions";

const UserReducers = createSlice({
    name: 'UserReducers',
    initialState: {
        data: [],
        loader: false,
        errors: {},
    },
    reducers: {},
    extraReducers: {
        // Get Orders
        [getOrders.pending]: (state) => {
            state.loader = true;
        },
        [getOrders.fulfilled]: (state, action) => {
            state.loader = false;
            state.data = action.payload;
        },
        [getOrders.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;
        },
    }
});

export default UserReducers.reducer;