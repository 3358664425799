import React from "react";
import { Collapse } from 'antd';
import { BreadcrumbNavigation } from "../Components";

import { dsinPath } from "../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Help = () => {
    const { Panel } = Collapse;
    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                {generateHeadTags({
                title: 'Help Desk | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'Help Desk | DentalStores.in',
                    url: `${dsinPath}/help-desk`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <BreadcrumbNavigation path1="Help Desk" link="" />
            <div className="about__container">
                <div style={{textAlign: "center", marginBottom: "2rem"}}>
                    <p style={{fontSize: 18, fontWeight: 600, color: "#181d3e", margin: 0}}>Cancellation & Refund Policy</p>
                    <p style={{fontSize: 13, width: 500, margin: "auto"}}>DentalStores.in believes in & we can helping its customers as far as possible, and has therefore a liberal cancellation policy.</p>
                </div>
                <div className="data-content px-md-5">
                    <Collapse defaultActiveKey={['1']} bordered={false}>
                        <Panel header="How do I cancel an order on DentalStores.in?" key="1">
                            <p>If unfortunately you have to cancel an order, please do so within 24 hours of placing the order.</p>
                            {/* <!--First--> */}
                            <p><u><b>For outright cancellations by you:</b></u></p>
                            <p>-     If you cancel your order before your product has shipped, we will refund the entire amount after deducting the actual cost incurred by us in such arrangements made just before shipment.</p>
                            {/* <!--Second--> */}
                            <p><u><b>If the cancellation is after your product has shipped:</b></u></p>
                            <p>- If you received the product, it will be eligible for replacement, only in cases where there are defects found with the product.</p>
                        </Panel>
                        <Panel header="Refund Policy" key="2">
                            <p> Refunds will be made in the same form that the payment is received within 15 working days from the date of cancellation of order except in cases of disputes which may take its own course.</p>
                            <p>Refund Will Be Credit to Store credit and customer can use it on next purchase.</p>
                        </Panel>
                        <Panel header="Return Policy" key="3">
                            <p>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to the concerned manufacturer. Let us know & we will try our level best to help you regarding the same.</p>
                            <p>If you think, you have received the product in a bad or unexceptable condition or if the packaging is tampered with or damaged before delivery, please refuse to accept the package and return the package to the delivery person. Also, please call our customer care at xxxxxxxxxx or email us at contact@DentalStores.in mentioning your Order ID. We will personally ensure that a brand new replacement is issued to you with no additional cost. Please make sure that the original product tag and packing is intact when you send us the product back.</p>
                            <p>Apart from condition reserved herein above, the following products shall not be eligible for return or replacement, viz:</p>
                            <ul>
                                <li>Any product that exhibits physical damage to the box or to the product;</li>
                                <li>Any product that is returned without all original packaging and accessories, including the retail box, manuals, cables, and all other items originally included with the product at the time of delivery;</li>
                                <li>Any product without a valid, readable, untampered serial number, including but not limited to products with missing, damaged, altered, or otherwise unreadable serial number;</li>
                                <li>Any product from which the UPC (Universal Product Code) has been removed from its original packaging.</li>
                                <li>Equipments such as Dental Chairs, Compressors, Suction, X-Ray Units, RVG Sensor, Endo Motors, Portable X-Rays, Apex Locators, Scalers, Micro Motors, Implant Motors, Piezo Surgery Motor, Bleaching Lights, Curing Lights etc. will not be accepted back as these comes under non-eligibility for return goods.</li>
                                <li>Lab equipments like Induction Casting Machines, Porcelain Furnace, Model Trimmers, Duplicating Unit, Acrylizers, Light Cure Ovens, Articulators / Face Bows, Vacuum Forming, Pressure Forming machines, Micro Motors etc. cannot be returned back.</li>
                                <li>Due to health, hygienic and safety reasons, Products which relate to personal hygiene and self-care cannot be returned. Products that come in contact with the body cannot be returned because they are single use items. No hygiene Product can be returned if the package has been opened.</li>
                                <li>Products directly used on Patients such as Myofunctional Appliances, Headgear Appliances, Water Flossers etc. won’t be accepted back.</li>
                                <li>DentalStores may charge a cancellation or return fee as specified in the cancellation and returns FAQs, to facilitate the return shipping process.</li>
                                <li>In case of special orders, where Products are procured specifically for you, and in case of large orders (value more than Rs 10,000), DentalStores may reject your return request at its own discretion. This is necessary as the manufacturers of the particular Products may not entertain such specific return requests.</li>
                            </ul>
                        </Panel>
                        <Panel header="Our Terms of Use" key="4">
                            <p>Our portal offers comparison shopping solutions and the use of the portal is expressly conditioned on your acceptance without modification of the terms and conditions. Your use of the Web site constitutes your agreement to all such terms and conditions. Do not use this site unless you agree above terms and conditions.</p>
                        </Panel>
                        <Panel header="Ownership" key="5">
                            <p>The portal, and each of its modules, together with the arrangement and compilation of the content found on this site, is the copy-righted property of DentalStores.in and its subsidiary company /or various third party providers and distributors. Much of the content found on this site is owned by third party providers and distributors and other information providers. In addition, the trademarks, logos and service marks displayed on this site (collectively, the "Trademarks") are registered and common law Trademarks of DentalStores.in., its affiliates, and various third parties. Nothing contained on this site should be construed as granting, by implication, or otherwise, any license or right to use any of the Trademarks without the written permission of DentalStores.in., or such other party that may own the Trademarks.</p>
                        </Panel>
                        <Panel header="Limitations of use" key="6">
                            <p>The portal grants you a limited, non- transferable license to use the portal in accordance with the terms and conditions of the Agreement. You may only use this portal to make legitimate inquiries, reservations or purchases and shall not use this portal for any other purposes, including without limitation, to make any speculative or fraudulent reservation or any reservation or purchase in anticipation of demand.
                                This portal and the content provided in this portal, including the text, graphics, button icons, audio and video clips, digital downloads, data compilations and software, may not be copied, reproduced, republished, uploaded, posted, transmitted or distributed without the written permission of third party providers and distributors. You may download, display and print the materials presented on this site for non- commercial use only.
                            </p>
                        </Panel>
                        <Panel header="Privacy policy" key="7">
                            <p>The privacy policy is available for review at privacy policy and you agree that you have read the Privacy Policy and that the terms of such policy are reasonable and are acceptable to you. You consent to the use of your personal information by us and/or its third party providers and distributors in accordance with the terms of and for the purposes set forth in our Privacy Policy.</p>
                        </Panel>
                        <Panel header="Disclaimer of Warranty" key="8">
                            <p>The portal is provided by DentalStores.in "as it is" and you may use it at your sole risk. DentalStores.in and any third party providers and distributors make no warranty of any kind regarding this web site and/or any materials provided on this site. The information, software, products, and services published on the portal may include inaccuracies, or incomplete data. in particular, DentalStores.in and its affiliates do not guarantee the accuracy of, and disclaim liability for inaccuracies relating to the information and description of the dental products and any other products displayed on this portal (including, without limitation, photographs, list of amenities, general product descriptions etc.), much of which information is provided by the respective suppliers, or third parties. Changes are periodically added to the information herein. DentalStores.in and its affiliates, and/or their respective suppliers may make improvements, additions and/or changes on this web site at any time without providing any prior notice.</p>
                        </Panel>
                        <Panel header="Limitation of liability" key="9">
                            <p>DentalStores.in assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect your computer equipment or other property on account of your access to, use of, or browsing in this site or you're downloading of any materials, data, text, images, video or audio from the portal. in no event shall DentalStores.in or any third party providers or distributors be liable for any injury, loss, claim, damage, or any special, exemplary, indirect, incidental or consequential damages of any kind (including, but not limited to lost profits or lost savings), whether based in contract, tort, strict liability, or otherwise, which arises out of or is in any way connected with: (i) any use of this site or content found herein, (ii) any failure or delay (including, but not limited to the use of or inability to use any component of this site whether for information, comparisons or making reservations), or (iii) the performance or non performance by the portal or any third party providers or distributors, including, but not limited to, non performance resulting from reorganization, insolvency, dissolution or liquidation even if such party has been advised of the possibility of damages to such parties or any other party. DentalStores.in, its affiliates, and/or their respective suppliers hereby disclaim all warranties and conditions with regard to this information, software, products, and services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non infringement.</p>
                        </Panel>
                        <Panel header="Indemnification" key="10">
                            <p>You shall defend and indemnify DentalStores.in and any third party providers and distributors and their officers, directors, employees and agents from and against any claim, recoveries, losses, damages, fines, penalties, cause of action or demands, including without limitation reasonable legal and accounting fees, brought by or on your behalf in excess of the liability described herein or by third parties as a result of your use of this Portal.</p>
                        </Panel>
                        <Panel header="Termination" key="11">
                            <p>DentalStores.in may terminate this Agreement and these terms and conditions and/or the provision of any of the services at any time for any reason, including any improper use of this portal or your failure to comply with these terms and conditions. Such termination shall not affect any right to relief to which DentalStores.in and its third party providers and distributors may be entitled, at law or in equity. Upon termination of this User Agreement and these terms and conditions, all rights granted to you will terminate and revert to DentalStores.in and its third party providers or distributors, as applicable.</p>
                        </Panel>
                        <Panel header="Assignment" key="12">
                            <p>You may not assign, subcontract or delegate your rights, duties, or obligations here under.</p>
                        </Panel>
                        <Panel header="Modifications" key="13">
                            <p>DentalStores.in may any time modify this terms and conditions and your continued use of this portal will be conditioned upon the terms and conditions in force at the time of your use.</p>
                        </Panel>
                        <Panel header="Additional conditions" key="14">
                            <p>Additional conditions may apply to purchase of goods and services and other use of portions of this portal and you agree for the same.</p>
                        </Panel>
                        <Panel header="Severability" key="15">
                            <p>These terms and conditions shall deemed to be severable, In the event that any provision is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                        </Panel>
                        <Panel header="No resale of service" key="16">
                            <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service.</p>
                        </Panel>
                        <Panel header="General" key="17">
                            <p>This agreement together with any terms and conditions referred to here in constitute the entire agreement between us relating to subject matter there of, and may not be amended or modified except by writing or by making such amendments or modifications available on this portal.</p>
                        </Panel>
                        <Panel header="Refund and cancellation" key="18">
                            <p>Cancellations will be considered only if the request is made within 24 hours of placing an order. However, the cancellation request will not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them. All Returns are subject to approval and re-stocking charges of 10%.</p>
                        </Panel>
                        <Panel header="Prices" key="19">
                            <p>Prices are inclusive of GST. The Company reserves the right to ammend the prices without notice. Prices mentioned on our site are in INR (Indian National Rupee). Prices does not includes any State Entry Taxes, Octroi, These charges has to be bourne by the buyer.</p>
                        </Panel>
                        <Panel header="Shipping & Delivery policy" key="20">
                            <p>Orders are shipped through registered domestic courier companies and /or speed post only.
                                Delivery Period is Within 30 Working Days on receipt of confirmed order with the payment. DentalStores.in is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 30 working days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to registered address of the buyer as per the credit/debit card only at all times (Unless specified at the time of Order). DentalStores.in is in no way responsible for any damage to the order while in transit to the buyer. DentalStores.in is proud to use Payment Gateway for fast, easy and efficient secure payments. All major credit cards are accepted.
                                For Online Orders below Rs. 2,500/- Packaging & Forwarding Charges would be charged extra with minimum of Rs. 200/-. Goods can be shipped only within India.
                            </p>
                        </Panel>
                        <Panel header="Jurisdiction" key="21">
                            <p>DentalStores.in area of Jurisdiction will be Hyderabad.</p>
                        </Panel>
                        <Panel header="International Orders" key="22">
                            <p>International orders has to be made offline. User is requested to send the list via email to us.</p>
                        </Panel>
                        <Panel header="Schemes & offers" key="23">
                            <p>Company reserves the right to withdraw or / and change the schemes & offers without notice. Any items sold under Offers & schemes cannot be taken back under any circumstances. Items under Special Offer & Schemes are available till Stocks lasts.</p>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    )
}

export default Help;