import React from "react";
import { useSelector } from 'react-redux';
import currencyFormatter from "currency-formatter";
import { Collapse } from 'antd';
import { Link } from "react-router-dom";
import { root } from "../Constants";

const OrderSummary = ({codAmt}) => {

    const { products, totalQtys, totalPrice, delivery, grandTotal, overWeight, couponData } = useSelector((state) => state.shoppingcart);
    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }
    const { Panel } = Collapse;

    return (
        <div className="order__summary-container">
            <h2 className="sc__pc-title">Order Summary</h2>
            <div className="os__price__list-group">
                <div className='os__price-item'>
                    <span>Cart Subtotal</span>
                    {overWeight > 0 ?
                        <span align="right">{formatAmount(totalPrice - overWeight)}</span> :
                        <span align="right">{formatAmount(totalPrice)}</span>}
                </div>
                {overWeight > 0 ?
                    <div className="os__price-item">
                        <span>Overweight delivery charges</span>
                        <span>{formatAmount(overWeight)}</span>
                    </div>
                : null}
                {couponData.value ?
                    <div className="os__price-item" style={{color: "#3bb896", fontWeight: 500}}>
                        <span>Discount {couponData.code ? `(${couponData.code}) ` : null} </span>
                        <span>-{formatAmount(couponData.value)}</span>
                    </div> 
                : null }
                <div className='os__price-item'>
                    <span>Delivery Charges</span>
                    <span align="right">{formatAmount(delivery)}</span>
                </div>
                {codAmt ? 
                    <div className='os__price-item' style={{color: "red", fontWeight: 500}}>
                        <span>Extra applicable on COD (5%)</span>
                        <span align="right">{formatAmount(codAmt)}</span>
                    </div>
                : null}
                <div className='os__price-item grand'>
                    <span>Order Total <span style={{fontSize: 11, fontWeight: 400}}>(Inclusive of all taxes)</span></span>
                    <span align="right">{codAmt ? formatAmount(grandTotal + codAmt) : formatAmount(grandTotal)}</span>
                </div>
            </div>
            <Collapse bordered={false} defaultActiveKey={['']}>
                <Panel header={`Items in cart (${totalQtys})`} key="1" style={{padding: 0}}>
                    <div className="d-block">
                        {products.map((product, index) => (
                            <Link to={product.title !== "variable" ? "/product/" + product.slug + ".html" : "#"} key={index}>
                                <div className="os__product">
                                    <img width="60" src={root+product.thumbnail} alt={product.slug} />
                                    <div className="product-title">
                                        {product.title !== "variable" ? product.title : product.variation_desc} 
                                        <p>
                                            Qty: {product.quantity} &nbsp;
                                            { product.dfee > 0 ? <span style={{ color: "#0d6efd" }}>+ {formatAmount(product.dfee)} Delivery fee</span> : null }
                                        </p>
                                    </div>
                                    <p style={{ fontWeight: 600 }}>
                                        {product.regular_price && product.sale_price ?
                                            <span>{formatAmount(product.sale_price * product.quantity)}</span>
                                            :
                                            <span>{formatAmount(product.regular_price * product.quantity)}</span>
                                        }
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

export default OrderSummary;