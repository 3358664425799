import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProducts = createAsyncThunk('products', async () => {
    // products/pending  |  products/fullfilled  |  products/rejected
    const response = await axios.request({
                        method: 'GET',
                        url: '/api/allproducts',
                     });
    return response.data;
});

export const getProduct = createAsyncThunk('product', async (slug) => {
    // product/pending  |  product/fullfilled  |  product/rejected
    const response = await axios.request({
                        method: 'GET',
                        url: `/api/product/${slug}`,
                     });
    return response.data;
})

export const getQuery = createAsyncThunk('search', async (query) => {
    // search/pending  |  search/fullfilled  |  search/rejected
    const response = await axios.request({
                        method: 'GET',
                        url: `/api/product?search=${query}`,
                     });
    return response.data;
})
