import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

// Persist Store
import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import BrandReducers from "./Brands/BrandReducers";
import CategoryReducers from "./Categories/CategoryReducers";
import ProductsReducer from "./Products/ProductsReducer";
import CartReducers from "./ShoppingCart/CartReducers";
import UserReducers from "./User/UserReducers";

const reducers = combineReducers({
    brands: BrandReducers,
    categories: CategoryReducers,
    products: ProductsReducer,
    shoppingcart: CartReducers,
    user: UserReducers,
});

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: ['products', 'categories', 'brands', 'user'],
    whitelist: ['shoppingcart'],
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    middleware: [...getDefaultMiddleware({immutableCheck: false, serializableCheck: false})]
});

const persistor = persistStore(store);

export { store, persistor };