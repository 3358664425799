import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { HiPencilAlt } from "react-icons/hi";
import { useNavigate, Link } from "react-router-dom";
import { Breadcrumb, Spin, Modal } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { FiEye, FiEyeOff } from "react-icons/fi";

import { dsinPath } from "../../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Details = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const { shippingAdrs } = useSelector((state) => state.shoppingcart);

    const [pInput, setPI] = useState({
        new: '',
        current: '',
        confirm: '',
        error_list: [],
    });

    const passHandleInput = (e) => {
        e.persist();
        setPI({ ...pInput, [e.target.name]: e.target.value });
    }

    const passSubmit = (e) => {
        e.preventDefault();
        setLoad(true);

        const data = {
            new: pInput.new,
            current: pInput.current,
            confirm: pInput.confirm,
        }

        axios.post('api/change-password', data).then(res => {
            setLoad(false);
            if (res.data.status === 200) {
                Modal.success({
                    title: 'Your password has been changed successfully.',
                    afterClose: () => window.location.reload()
                });

            } else if (res.data.status === 401) {
                Modal.error({
                    title: res.data.message,
                });
            } else {
                setPI({ ...pInput, error_list: res.data.validation_errors });
            }
        });
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                title: 'Reset Password | DentalStores.in',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'Reset Password | DentalStores.in',
                    url: `${dsinPath}`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            {load ? <Spin tip="Please wait..." className="spin__loading" /> :
                <div className="myaccount">
                    <Breadcrumb className="myaccount__breadcrumb-container">
                        <Breadcrumb.Item><Link to="/"><HomeOutlined /></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/account">My Account</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Account details</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="dashboard__section">
                        <div className="ac__password-grid">
                            <div className="rp__address-container" style={{margin: 0}}>
                                <p className="rp__address-title">Shipping Address:</p>
                                <span>{shippingAdrs.name}</span>
                                <span>{shippingAdrs.address}</span>
                                <span>{shippingAdrs.city}, {shippingAdrs.state} {shippingAdrs.pincode}.</span>
                                <span>{shippingAdrs.country}</span>
                                {shippingAdrs.gstin !== '' ? <span>GSTIN: {shippingAdrs.gstin}</span> : null}
                                <div onClick={() => navigate('/account/address')} className="rp__sa_edit"><HiPencilAlt style={{ marginRight: 3 }} />Edit</div>
                            </div>
                            <div className="ac__password-container">
                                <div className="pass__title">PASSWORD CHANGE 
                                    <div onClick={() => setShowPass(!showPass)} style={{display: "inline-block", marginLeft: 10, cursor: "pointer"}}>
                                        {!showPass ? <FiEyeOff size={15}  />: <FiEye size={15} />}
                                        </div>
                                </div>
                                <form onSubmit={passSubmit}>
                                    <div className="ac__form-group">
                                        <label htmlFor="">Current Password (leave blank to leave unchanged)</label>
                                        <input type={!showPass ? "password" : "text"} name="current" onChange={passHandleInput} value={pInput.current} className="input" />
                                        <span className="text-danger small">{pInput.error_list.current}</span>
                                    </div>
                                    <div className="ac__form-group">
                                        <label htmlFor="">New Password (leave blank to leave unchanged)</label>
                                        <input type={!showPass ? "password" : "text"} name="new" onChange={passHandleInput} value={pInput.new} className="input" />
                                        <span className="text-danger small">{pInput.error_list.new}</span>
                                    </div>
                                    <div className="ac__form-group">
                                        <label htmlFor="">Confirm New Password</label>
                                        <input type={!showPass ? "password" : "text"} name="confirm" onChange={passHandleInput} value={pInput.confirm} className="input" />
                                        <span className="text-danger small">{pInput.error_list.confirm}</span>
                                    </div>
                                    <button className="reset-btn">Save Changes</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Details;