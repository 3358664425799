import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { root } from '../../Constants';
import 'swiper/css';
import "swiper/css/navigation";
import './style.css';
import { BiChevronsRight } from "react-icons/bi";
import currencyFormatter from "currency-formatter";
import { Link } from "react-router-dom";

const FetchBC = ({ type = [], title, link }) => {
    // Price formatting function
    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }

    // Function to shuffle the array
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    }

    // Shuffle products and select the first 6
    const shuffledProducts = shuffleArray(type).slice(0, 6);

    // Function to find minimum regular and sale prices
    const minmax = (product) => {
        const regularPrices = product.pro_var.map(ver => parseInt(ver.regular_price));
        const salePrices = product.pro_var.map(ver => parseInt(ver.sale_price));
        return {
            minRegularPrice: Math.min(...regularPrices),
            minSalePrice: Math.min(...salePrices)
        };
    }

    return (
        <section className="section cb__section">
            <div className="section__heading">
                <h2 className="section__title">{title}<span>.</span></h2>
                <Link to={link}><button className="view__all-btn">View <BiChevronsRight /></button></Link>
            </div>
            <Swiper
                grabCursor={true}
                spaceBetween={5}
                slidesPerView="auto"
                loop={true}
                className="product__container pgridSwiper"
            >
                {shuffledProducts.length > 0 ? shuffledProducts.map((product, index) => {
                    const { minRegularPrice, minSalePrice } = product.pro_var.length > 0 ? minmax(product) : {};
                    return (
                        <SwiperSlide className="product__card" key={index}>
                            <Link to={"/product/" + product.slug + ".html"}>
                                <div className="product__card-img">
                                    <img src={root + product.thumbnail} alt={`Image of ${product.title}`} />
                                </div>
                                <div className="product__data">
                                    <h2 className="product__price">
                                        {product.pro_var.length > 0 ?
                                            <>
                                                <span className="starting">Starting at: </span>
                                                <span>{formatAmount(minSalePrice || minRegularPrice)}</span>
                                            </>
                                            :
                                            <>
                                                {product.regular_price && product.sale_price ?
                                                    <>
                                                        <span>{formatAmount(product.sale_price)}</span>&nbsp;
                                                        <span className="mrp">{formatAmount(product.regular_price)}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>{formatAmount(product.regular_price)}</span>
                                                    </>
                                                }
                                            </>
                                        }
                                    </h2>
                                    <h3 className="product__title">{product.title.substring(0, 43)}{product.title.length >= 43 && '...'}</h3>
                                    <p className="product__description">{product.categories.length > 0 ? product.categories[0].name : null}</p>
                                </div>
                            </Link>
                        </SwiperSlide>
                    );
                }) : <SwiperSlide></SwiperSlide>}
            </Swiper>
        </section>
    )
}

export default FetchBC;
