import React, { useState, useRef, useEffect } from "react";
import { FormInput, TextButton } from "../../Components";
import { utils } from "../../utils";
import { FiCheckCircle, FiXCircle, FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { BsStarFill } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "./style.css";
import axios from "axios";
import { Modal } from 'antd';

import { dsinPath } from "../../Constants";
import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';


const Login = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem('auth_token')) navigate('/');
    }, [navigate])

    const [condition, setCondition] = useState(false);

    const reviews = [
        {
            id: 1,
            content: "The order placement, confirmation and delivery provided  by DentalStores.in is quick and hassle free. The quality of products and packaging was good. Customer support services are efficient. Thanx for great service! :)",
            name: "Monica M",
            review: 5
        },
        {
            id: 2,
            content: "This is Dr. Khaja Hussain, i have been ordering products from Dentalstores.in. Products are genuine and its easy to order from their web page. Timely delivery (within 1day / 2 day delivery for me). Customer care is good with timely contact after placing an order.",
            name: "Dr. Khaja Hussain",
            review: 5
        },
        {
            id: 3,
            content: "Am gokul.... Recently prachased from dental store.. Products are packed in good conditions.. And their customer service was nice and friendly....they gave product details like expiry date without any hesitation.....am overwhelmed with their hospitality..... Thank you team.... Best of luck...",
            name: "Gokulan Balu",
            review: 5
        },
        {
            id: 4,
            content: "Super fast delivery.. genuine product. Thank you so much. 🙏🏻",
            name: "Dr. Bikash Das",
            review: 5
        },
        {
            id: 5,
            content: "Delivery is fast and product are true to quality. 👍",
            name: "Pooja Disale",
            review: 5
        },
        {
            id: 6,
            content: "Very reliable. Fast shipping. I like that they keep you updated through WhatsApp.",
            name: "Joe Ralte",
            review: 5
        },
        {
            id: 7,
            content: "Quick order processing and delivery. Loved the service.",
            name: "Abhilash Alfred",
            review: 5
        },
        {
            id: 8,
            content: "Quicker and reasonable than other players for now.",
            name: "Umair Parkar",
            review: 5
        }
    ]
    
    const SignIn = () => {
        const [load, setLoad] = useState(false);
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [emailError, setEmailError] = useState("");
        const [showPass, setShowPass] = useState(false);
        const [passwordError, setPasswordError] = useState("");
        function isEnableButton() {
            return email !== "" && password !== "" && 
                   emailError === "" && passwordError === ""
        }

        const submit = (e) => {
            e.preventDefault();
            setLoad(true)
            const data = { email: email, password: password}

            axios.get('/sanctum/csrf-cookie').then(() => {
                axios.post('api/login', data).then(res => {
                    setLoad(false);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.username);
                        localStorage.setItem('auth_email', res.data.email);
                        localStorage.setItem('auth_details', JSON.stringify(res.data.auth));
    
                        Modal.success({
                            title: 'Login Success!',
                            content: 'You have been successfully logged into DentalStores.in',
                            afterClose: () => window.location.reload()
                        });
                        navigate(-1);
                    } else if (res.data.status === 401) {
                        Modal.error({title: "Incorrect Credentials", content: "Unable to log in. Please check your email/password and try again."});
                    } else {
                        Modal.error({title: "Incorrect Credentials", content: "The email and password you entered did not match our records. Please double-check and try again."});
                    }
                });
            });
        }

        return (
            <div className="login__container">
                <h1 className="section__title">Login<span>.</span></h1>
                <p className="section__small-title">Welcome back! Please enter your details.</p>
                <div className="signin__with-google">
                    <img src="./google.png" alt="google" width={18} />
                    <span>Sign in with Google</span>
                </div>
                <div className="signin__with-email"></div>
                <form onSubmit={submit} className="login__form-container">
                    {/* Form Inputs */}
                    <FormInput
                        rqd={true}
                        label="Email"
                        placeholder="example@email.com"
                        keyboardType="email"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        onChange={(value) => {
                            // Validate Email
                            utils.validEmail(value.target.value, setEmailError)
                            setEmail(value.target.value.toLowerCase())
                        }}
                        errorMsg={emailError}
                        appendComponent={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {email === "" || (email !== "" && emailError === "") ?
                                    <FiCheckCircle size={20} color={email === "" ? "#808080" : "#00b878"} />
                                    : <FiXCircle size={20} color="#f35" />
                                }
                            </div>
                        }
                    />
                    <FormInput
                        rqd={true}
                        label="Password"
                        placeholder="Min. 8 character"
                        keyboardType={!showPass ? "password" : "text"}
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        containerStyle={{
                            marginTop: 20
                        }}
                        onChange={(value) => {
                            // Validate Password
                            utils.validPassword(value.target.value, setPasswordError)
                            setPassword(value.target.value)
                        }}
                        errorMsg={passwordError}
                        appendComponent={
                            <div
                                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                onClick={() => setShowPass(!showPass)}
                            >
                                {!showPass ? <FiEyeOff size={20} color={password === "" || password.length < 8 ? "#808080" : "#00b878"} />
                                    : <FiEye size={20} color={password === "" || password.length < 8 ? "#808080" : "#00b878"} />
                                }
                            </div>
                        }
                    />
                    {/* Save me & Forgot Password */}
                    <Link to="/" className="forgot__password"> <span>Forgot password?</span> </Link>
                    {/* Sign In */}
                    <TextButton
                        label={load ? "Please wait..." : "Login"}
                        buttonContainerStyle={{
                            height: 60,
                            borderRadius: '2rem',
                            margin: '1rem 0',
                            backgroundColor: isEnableButton() ? "hsl(232, 44%, 17%)" : 'rgba(0,0,0,0.2)'
                        }}
                        dis={!isEnableButton() ? true : false}
                    />
                </form>
                <div className="not__registered-yet">
                    Not registered yet? <span onClick={() => setCondition(true)}>Create an Account</span>
                </div>
            </div>
        )
    }

    const SignUp = () => {
        const [load, setLoad] = useState(false);
        const [name, setName] = useState("");
        const [number, setNumber] = useState("");
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [showPass, setShowPass] = useState(false);

        const [nameError, setNameError] = useState("");
        const [numberError, setNumberError] = useState("");
        const [emailError, setEmailError] = useState("");
        const [passwordError, setPasswordError] = useState("");

        function isEnableButton() {
            return name !== "" && number !== "" && email !== "" && password !== "" &&
                   nameError === "" && numberError === "" && emailError === "" && passwordError === ""
        }

        const submit = (e) => {
            e.preventDefault();
            setLoad(true)
            const data = { name: name, mobile: number, email: email, password: password }

            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('api/registration', data).then(res => {
                    setLoad(false);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.username);
                        localStorage.setItem('auth_email', res.data.email);
                        localStorage.setItem('auth_details', JSON.stringify(res.data.auth));
    
                        Modal.success({
                            title: "Thanks for signing up.",
                            content: "Congratulations, your account has been successfully created.",
                            afterClose: () => window.location.reload()
                        });
    
                        navigate(-1);
                    } else {
                        Modal.error({
                            title: "Something went wrong",  
                            content: `It looks like there's already an account with ${email}. If it's you, go back and sign in. Otherwise, choose another email i'd.`
                        })
                    }
                });
            });
        }

        return (
            <div className="signup__container">
                <h1 className="section__title">Sign Up<span>.</span></h1>
                <div className="section__small-title">
                    <p className="not__registered-yet">
                        Already have an Account? <span onClick={() => setCondition(false)}>Sign in</span>
                    </p>
                </div>
                <div className="signup__with-google">
                    <img src="./google.png" alt="google" width={18} />
                    <span>Sign up with Google</span>
                </div>
                <div className="signup__with-email"></div>
                <form onSubmit={submit} className="signup__form-container">
                    <FormInput
                        rqd={true}
                        label="Name"
                        placeholder="for ex. Yourname"
                        keyboardType="text"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        onChange={(value) => {
                            // Validate Name
                            utils.validUsername(value.target.value, setNameError)
                            setName(value.target.value)
                        }}
                        errorMsg={nameError}
                        appendComponent={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {name === "" || (name !== "" && nameError === "") ?
                                    <FiCheckCircle size={20} color={name === "" || name.length < 5 ? "#808080" : "#00b878"} />
                                    : <FiXCircle size={20} color="#f35" />

                                }
                            </div>
                        }
                    />
                    <FormInput
                        rqd={true}
                        label="WhatsApp no."
                        placeholder="WhatsApp no."
                        keyboardType="text"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        containerStyle={{
                            marginTop: 20
                        }}
                        onChange={(value) => {
                            // Validate Number
                            utils.validNumber(value.target.value, setNumberError)
                            setNumber(value.target.value)
                        }}
                        errorMsg={numberError}
                        appendComponent={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {number === "" || (number !== "" && numberError === "") ?
                                    <FiCheckCircle size={20} color={number === "" ? "#808080" : "#00b878"} />
                                    : <FiXCircle size={20} color="#f35" />

                                }
                            </div>
                        }
                    />
                    <FormInput
                        rqd={true}
                        label="Email"
                        placeholder="example@email.com"
                        keyboardType="email"
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        containerStyle={{
                            marginTop: 20
                        }}
                        onChange={(value) => {
                            // Validate Email
                            utils.validEmail(value.target.value, setEmailError)
                            setEmail(value.target.value)
                        }}
                        errorMsg={emailError}
                        appendComponent={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {email === "" || (email !== "" && emailError === "") ?
                                    <FiCheckCircle size={20} color={email === "" ? "#808080" : "#00b878"} />
                                    : <FiXCircle size={20} color="#f35" />

                                }
                            </div>
                        }
                    />
                    <FormInput
                        rqd={true}
                        label="Password"
                        placeholder="Min. 8 character"
                        keyboardType={!showPass ? "password" : "text"}
                        textInputStyles={{
                            borderRadius: "2rem",
                            border: "1px solid #e0e0e0",
                            padding: "0 20px"
                        }}
                        containerStyle={{
                            marginTop: 20
                        }}
                        onChange={(value) => {
                            // Validate Password
                            utils.validPassword(value.target.value, setPasswordError)
                            setPassword(value.target.value)
                        }}
                        errorMsg={passwordError}
                        appendComponent={
                            <div
                                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                onClick={() => setShowPass(!showPass)}
                            >
                                {!showPass ? <FiEyeOff size={20} color={password === "" || password.length < 8 ? "#808080" : "#00b878"} />
                                    : <FiEye size={20} color={password === "" || password.length < 8 ? "#808080" : "#00b878"} />
                                }
                            </div>
                        }
                    />
                    {/* Personal Data */}
                    <div className="personal__data">
                        Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <span>privacy policy.</span>
                    </div>
                    {/* Sign Up */}
                    <TextButton
                        label={load ? "Please wait..." : "Sing Up"}
                        buttonContainerStyle={{
                            height: 60,
                            borderRadius: '2rem',
                            marginTop: '2.5rem',
                            backgroundColor: isEnableButton() ? "hsl(232, 44%, 17%)" : 'rgba(0,0,0,0.2)'
                        }}
                        dis={!isEnableButton() ? true : false}
                    />
                </form>
            </div>
        )
    }

    // Navigation Prev/Next Ref
    const fc_NPR = useRef(null);
    const fc_NNR = useRef(null);

    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${dsinPath}/login`} />
                {generateHeadTags({
                title: 'Login | Sign up',
                description: 'One-stop Solution for all your dental needs. Shop at India\'s Favourite online dental store.',
                openGraph: {
                    title: 'Login | Sign up',
                    url: `${dsinPath}/login`,
                    'article:author': 'DentalStores.in',
                },
                })}
            </Helmet>
            <section className="login__section-container">
                {!condition ? <SignIn /> : <SignUp />}
                <div className="testimonials__container">

                    <Swiper
                        modules={[Navigation, Autoplay]}
                        grabCursor={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            prevEl: fc_NPR.current,
                            nextEl: fc_NNR.current,
                        }}
                        onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                            // Override prevEl & nextEl now that refs are defined
                            swiper.params.navigation.prevEl = fc_NPR.current
                            swiper.params.navigation.nextEl = fc_NNR.current
                    
                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        className="testContent testimonials__content"
                    >
                        {reviews.map((quote, index) => (
                            <SwiperSlide className="tms__quote" key={index}>
                                <p className="tms__quote-content">"{quote.content}"</p>
                                <div className="tms__quote-footer">
                                    <span className="tms__quote-name">{quote.name}</span>
                                    <div className="tms__quote-stars">
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev" ref={fc_NPR}>
                            <BiChevronLeft />
                        </div>
                        <div className="swiper-button-next" ref={fc_NNR}>
                            <BiChevronRight />
                        </div>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Login;