import React, { useEffect, useState } from "react";
import axios from "axios";
import { Radio, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import { OrderSummary } from "../../Components";
import { useSelector, useDispatch } from "react-redux";
import { HiPencilAlt } from "react-icons/hi";
import { TextButton } from "../../Components";
import currencyFormatter from "currency-formatter";
import { root, dsinPath } from "../../Constants";
import { CODCHK } from "../../Redux/ShoppingCart/CartActions";

import { Helmet } from "react-helmet";
import { generateHeadTags } from 'react-seo-tools/lib/generateHeadTags';

const Payment = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const { products, shippingAdrs, delivery, grandTotal, couponData, isCOD, totalQtys, overWeight, totalPrice } = useSelector((state) => state.shoppingcart);
    const [COD, setCOD] = useState(false);
    const [codAmt, setCODAmt] = useState(0);
    const [note, setNote] = useState(null);
    const [noteLength, setNoteLength] = useState(0);
    const [product_info, setInfo] = useState(null);
    const [payment_id, setPayment_id] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!localStorage.getItem('auth_token')) navigate('/login');

        if(totalQtys === 0) navigate("/");

        function setProInfo() {
            products.map((product, index) => {
                let price = 0;
                if (product.regular_price && product.sale_price) {
                    price = product.sale_price;
                } else {
                    price = product.regular_price;
                }
                if(product.title === "variable") {
                    setInfo(prevState => ({ ...prevState, [`"'${index}'"`]: { product_img: root + product.thumbnail, product_id: product.id, product_title: product.variation_desc, product_sp: price, product_qty: product.quantity, product_subtotal: price * product.quantity, dfee: product.dfee } }));
                } else {
                    setInfo(prevState => ({ ...prevState, [`"'${index}'"`]: { product_img: root + product.thumbnail, product_id: product.id, product_title: product.title, product_sp: price, product_qty: product.quantity, product_subtotal: price * product.quantity, dfee: product.dfee } }));
                }
                return null;
            })
        }
        setProInfo();

        // COD Check
        if(products.find(e => e.codchk === "0")) {
            dispatch(CODCHK(false));
        } else {
            dispatch(CODCHK(true));
        }

    }, [dispatch, navigate, products, totalQtys]);

    const formatAmount = (price) => {
        return currencyFormatter.format(parseInt(price), { code: "INR", precision: 0 });
    }

    const Agree = () => {
        return (
            <>
                <div style={{ 
                    marginLeft: "35px",
                    border: "1px dashed #096dd9",
                    borderRadius: "5px",
                    padding: "10px",
                    background: "rgb(9 109 217 / 5%)",
                    lineHeight: 2,
                    fontSize: "14px",
                    marginBlockEnd: 30
                 }}>
                    <p style={{ lineHeight: 2, fontSize: 14 }}>
                        All COD orders need to pay small advance payment to avoid fake orders and to minimise cancel orders / order getting returned. The rest of the COD payment can be done at the time of delivery. This will avoid our shipping costs losses and we can continue offering prices to our customers at lowest prices as usual. Our team will contact you for making advance payment soon.
                    </p>
                    <p style={{ fontWeight: 500, marginTop: 10 }}>Please note without receiving advance payment, we will not ship COD orders.</p>
                </div>
                <div className="cod__note">
                    <p className="cod__extra">* 5% extra applicable on COD.</p>
                    <div className="note__1">
                        <p className='heading'>Why 5% extra on COD?</p>
                        <p className="small">Logistics / Courier charges us 5% extra for COD shipments. Since we work on very low profit margins, we have to pass the charges on our customers. To save 5% extra charges, customers are advised to pay upfront at the time of ordering.</p>
                    </div>
                    <div className="note__2">
                        <p className='heading'>Is there any order value limit on COD Orders?</p>
                        <p className="small">The maximum COD order value is Rs. 10000.00 only. If you are ordering more than Rs. 10000.00 value, we suggest you to pay in advance at the time of order.</p>
                    </div>
                    <p className="cod__order_confirmation">We'll email you an order confirmation with details and tracking info.</p>
                    <p className="cod__free__offers">Please note Discount and Free offers are not available on cod orders. You can avail good deals on prepaid orders.</p>
                    <div style={{marginTop: "1.5rem"}}>
                        <p style={{color: "lightslategrey"}}><span style={{fontWeight: 600}}>Note:</span>&nbsp;&nbsp;{formatAmount(codAmt)} will be charged on using Cash on Delivery. Select one of the online payment modes to save {formatAmount(codAmt)} and to Stay Safe.</p>
                        <TextButton
                            label={load ? "Please wait..." : "Place Order"}
                            buttonContainerStyle={{
                                height: 50,
                                maxWidth: 300,
                                width: "100%",
                                borderRadius: 3,
                                fontSize: "initial"
                            }}
                            onClick={load ? null : codSubmit}
                        />
                    </div>
                </div>
            </>
        )
    }

    const OnlinePay = () => (
        <TextButton
            label={load ? "Please wait..." : "Place Order & Pay"}
            buttonContainerStyle={{
                height: 50,
                maxWidth: 300,
                width: "100%",
                marginLeft: 35,
                marginTop: "1rem",
                borderRadius: 3,
            }}
            cls="onlineBtn"
            onClick={load ? null : onlineSubmit}
        />
    )

    const onChange = (e) => {
        if (e.target.value === "cod") {
            if (grandTotal < 500) {
                Modal.warning({
                    title: 'Cash on Delivery Not Available',
                    content: 'Cash on Delivery is not available for orders below Rs. 500. Please select an online payment method.',
                });
                setCOD(false);
                setCODAmt(0);
            } else {
                setCOD(true);
                let amt = Math.round(totalPrice * 5 / 100);
                setCODAmt(amt);
            }
            // setCOD(true);
            // let amt = Math.round(totalPrice * 5/100);
            // setCODAmt(amt);
        } else {
            setCOD(false);
            setCODAmt(0);
        }
    };

    const codSubmit = () => {
        setLoad(true);
        const data = {
            proinfo: product_info,
            payment_id: payment_id,
            coupon_desc: null,
            overweight_charges: overWeight,
            cod_charges: codAmt,
            delivery_charges: delivery,
            totalAmount: grandTotal + codAmt,
            user_note: note
        }

        axios.post('api/codpayment', data).then(res => {
            setLoad(false);
            if (res.data.status === 200) {
                localStorage.removeItem('persist:root', 0);
                Modal.success({
                    title: 'Order Successfully Placed',
                    content: 'Thank you for ordering. We received your order and will begin processing it soon.',
                    afterClose: () => {
                                        window.location.reload(); 
                                        localStorage.removeItem('persist:root');
                                        localStorage.removeItem('coupon');
                                        localStorage.removeItem('address');
                                        console.log(res.data.dsinTracking);
                                        window.gtag('event', 'conversion', {
                                            'send_to': 'AW-575742636/YmOxCJC_tu4BEKzFxJIC',
                                            'transaction_id': res.data.dsinTracking
                                        });
                                      }
                });
                navigate('/');
            }
        });
    }

    const onlineSubmit = () => {
        setLoad(true);
        const options = {
            "key": "rzp_live_UCaGUiqyAde5VK",  // Live
            // "key": "rzp_test_Dor4Bw7UDtpJmH", // testing 
            "amount": grandTotal * 100,
            "name": "DentalStores.in",
            "prefill": {
                "name": shippingAdrs.name,
                "email": localStorage.getItem('auth_email'),
                "contact": shippingAdrs.mobile
            },
            "handler": function (response) {
                setPayment_id(response.razorpay_payment_id);
                // Coupon code
                let setcc = null;
                if (couponData.value) {
                    setcc = "Coupon: " + couponData.code + " (-₹" + couponData.value + ")";
                } else { setcc = null }

                const data = {
                    proinfo: product_info,
                    payment_id: payment_id,
                    coupon_desc: setcc,
                    overweight_charges: overWeight,
                    delivery_charges: delivery,
                    totalAmount: grandTotal,
                    user_note: note
                }

                axios.post('api/onlinepayment', data).then(res => {
                    setLoad(false);
                    if (res.data.status === 200) {
                        Modal.success({
                            title: 'Order Successfully Placed',
                            content: 'Thank you for ordering. We received your order and will begin processing it soon.',
                            afterClose: () => {
                                                window.location.reload(); 
                                                localStorage.removeItem('persist:root');
                                                localStorage.removeItem('coupon');
                                                localStorage.removeItem('address');
                                                console.log(res.data.dsinTracking);
                                                window.gtag('event', 'conversion', {
                                                    'send_to': 'AW-575742636/YmOxCJC_tu4BEKzFxJIC',
                                                    'transaction_id': res.data.dsinTracking
                                                });
                                              }
                        });
                        navigate('/');
                    } else {
                        Modal.error({ title: "Something went wrong", content: res.data.message });
                    }
                });
            }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    return (
        <>
            <Helmet>   
                <meta name="robots" content="noindex, nofollow" />
                {generateHeadTags({
                    title: 'Review & Payments | DentalStores.in',
                    description: 'Shop Dental supplies online at the lowest prices in India. Free Shipping on qualifying orders.',
                    openGraph: {
                        title: 'Review & Payments | DentalStores.in',
                        url: `${dsinPath}/checkout/payment`,
                        'article:author': 'DentalStores.in',
                    },
                })}
            </Helmet>
            <div className="shipping__address-container">
                <div className="shipping__form-container">
                    <h1 className="section__title">Review & Payments</h1>
                    <div className="rp__address__note-container">
                        <div className="rp__address-container">
                            <p className="rp__address-title">Shipping Address:</p>
                            <span>{shippingAdrs.name}</span>
                            <span>{shippingAdrs.address}</span>
                            <span>{shippingAdrs.city}, {shippingAdrs.state} {shippingAdrs.pincode}.</span>
                            <span>{shippingAdrs.country}</span>
                            {shippingAdrs.gstin !== '' ? <span>GSTIN: {shippingAdrs.gstin}</span> : null}
                            <div onClick={() => { navigate('/checkout/shipping'); localStorage.removeItem('address') }} className="rp__sa_edit"><HiPencilAlt style={{ marginRight: 3 }} />Edit</div>
                        </div>
                        <div className="rp__anote-container">
                            <h3>Additional Notes</h3>
                            <textarea 
                                className="rp__anote-textarea" 
                                placeholder="Enter any additional information regarding your order."
                                onChange={(e) => {setNote(e.target.value); setNoteLength(e.target.value.length)}}
                                maxLength="180"
                            ></textarea>
                            <span style={{fontSize: 11, float: 'right'}}>{noteLength} / 180</span>
                        </div>
                    </div>
                    <div className="rp__payment_option">
                        <h3 style={{ marginBottom: 20 }}>Select a Payment Method</h3>
                        <Radio.Group onChange={onChange} defaultValue="online">
                            <Radio.Button value="online"><div className="span__radio" /> Debit Card / Credit Card / Net Banking / UPI</Radio.Button>
                            {!couponData.value && isCOD ?
                            <Radio.Button value="cod"><div className="span__radio" /> Cash on delivery</Radio.Button> : null}
                        </Radio.Group>
                    </div>
                    {COD ? <Agree /> : <OnlinePay />}
                </div>
                <OrderSummary codAmt={codAmt} />
            </div>
        </>
    )
}

export default Payment;