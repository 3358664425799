import React from 'react';
import { BiMoon, BiSearch, BiCategory, BiHeart, BiUserCircle } from "react-icons/bi";
import { FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LOGO from "./logo.png";

function Header() {

    const { totalQtys } = useSelector((state) => state.shoppingcart);

    return (
        <>
            {/* <!--==================== HEADER ====================--> */}
            <header className="header" id="header">
                <nav className="nav container">
                    <Link to="/" className="nav__logo">
                        <img src={LOGO} alt="dentalStores-in-logo" />
                    </Link>

                    <Link to="/" className="go__search">
                        <BiMoon />
                    </Link>

                    <Link to="/search" className="nav__search">
                        <BiSearch />
                        <input type="search" placeholder="Search for products, brands and more..." className="nav__search-input" />
                        <button className="button">Search</button>
                    </Link>

                    <div className="nav__menu">
                        <ul className="nav__list">
                            <li className="nav__item">
                                <Link to="/categories" className="nav__link active-link">
                                    <BiCategory />
                                    <span>All Categories</span>
                                </Link>
                            </li>
                            <li className="nav__item nav__item__search">
                                <Link to="/search" className="nav__link">
                                    <BiSearch />
                                    <span>Search</span>
                                </Link>
                            </li>
                            <li className="nav__item">
                                <Link to="/account/wishlist" className="nav__link">
                                    <BiHeart />
                                    <span>Wishlist</span>
                                </Link>
                            </li>
                            <li className="nav__item">
                                {localStorage.getItem('auth_name') ? 
                                <Link to="/account" className="nav__link">
                                    <BiUserCircle />
                                    <span className="sign">My Account</span>
                                </Link>
                                : 
                                <Link to="/login" className="nav__link">
                                    <BiUserCircle />
                                    <span className="sign">Sign in</span>
                                </Link>
                                }
                            </li>
                            <li className="nav__item">
                                <Link to="/cart" className="nav__link">
                                    <FiShoppingBag />
                                    <span className="count">{ totalQtys }</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header