import React from 'react';
import axios from 'axios';
import { root } from './Constants';
import { Header, Footer, ScrollToTop } from './Layout';
import {
    Home,
    Search,
    SingleProduct,
    Categories, CategoryShop,
    Brands, BrandShop,
    Cart,
    Login,
    Dashboard,
    Orders,
    Order,
    Address,
    Details,
    Wishlist,
    Shipping,
    Payment,
    NotFound
} from './Screens';
import { About, Help } from "./Pages";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

axios.defaults.baseURL = root;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
})

const App = () => {
    return (
        <Router basename="/">
            <ScrollToTop />
            <Header />
            <main className='container main'>
                <Routes>
                    {/* Root */}
                    <Route path="/" element={<Home />} />
                    {/* Search */}
                    <Route path="/search" element={<Search />} />
                    {/* Single product / Product detail page */}
                    <Route path="product/:slug.html" element={<SingleProduct />} />
                    {/* Brand */}
                    <Route exact path="/brands" element={<Brands />} />
                    <Route path="brand/:slug.html" element={<BrandShop />} />
                    {/* Category */}
                    <Route exact path="/categories" element={<Categories />} />
                    <Route path="/:slug.html" element={<CategoryShop />} />
                    {/* Shopping Cart */}
                    <Route exact path="/cart" element={<Cart />} />
                    {/* Auth */}
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/account" element={<Dashboard />} />
                    <Route exact path="/account/orders" element={<Orders />} />
                    <Route exact path="/account/order/:id" element={<Order />} />
                    <Route exact path="/account/address" element={<Address />} />
                    <Route exact path="/account/details" element={<Details />} />
                    <Route exact path="/account/wishlist" element={<Wishlist />} />
                    {/* Checkout */}
                    <Route exact path="/checkout/shipping" element={<Shipping />} />
                    <Route exact path="/checkout/payment" element={<Payment />} />
                    {/* Pages */}
                    <Route exact path="/about-us" element={<About />} />
                    <Route exact path="/help-desk" element={<Help />} />
                    {/* Not Found */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
            <Footer />
        </Router>
    )
}

export default App;
