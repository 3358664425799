import React from "react";

const FormInput = ({
    containerStyle,
    textInputStyles,
    label,
    placeholder,
    inputStyle,
    prependComponent,
    appendComponent,
    onChange,
    keyboardType="text",
    errorMsg="",
    rqd=false,
    value
}) => {
    return (
        <div style={{...containerStyle}}>
            {/* Label & Error msg  */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ color: "hsl(232, 44%, 17%)", fontWeight: 500}}>{label}</span>
                <span style={{ color: "hsl(352, 96%, 65%)", fontWeight: 500, fontSize: "0.8125rem"}}>{errorMsg}</span>
            </div>
            {/* Text Input */}
            <div
                style={{
                    display: "flex",
                    height: 55,
                    marginTop: 10,
                    ...textInputStyles
                }}
            >
                {prependComponent}
                <input 
                    style={{
                        width: "100%",
                        background: "none",
                        outline: "none",
                        border: "none",
                        ...inputStyle
                    }}
                    placeholder={placeholder}
                    type={keyboardType}
                    onChange={(text) => onChange(text)}
                    required={rqd}
                    autoComplete="none"
                    value={value}
                />
                {appendComponent}
            </div>
        </div>
    )
}

export default FormInput;